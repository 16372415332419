export const normalizeCity = (city) => {
  if (typeof city !== 'string') return ''

  const trimmed = city.trim();

  const articleRegex = /^(la|le|l')\s/i;
  const articleMatch = trimmed.match(articleRegex);

  let article = "";
  let rest = trimmed;

  if (articleMatch) {
    article = articleMatch[0].trim();
    rest = trimmed.slice(articleMatch[0].length);
  }

  const normalizedRest = rest
    .toLowerCase()
    .replace(/[\s-]+/g, '-')
    .replace(/(^|-)(\p{L})/gu, (match, sep, letter) => sep + letter.toUpperCase());

  if (article) {
    article = article.charAt(0).toUpperCase() + article.slice(1).toLowerCase();
    return `${article} ${normalizedRest}`;
  }

  return trimmed
    .toLowerCase()
    .replace(/[\s-]+/g, '-')
    .replace(/(^|-)(\p{L})/gu, (match, sep, letter) => sep + letter.toUpperCase());
};

export const capitalizeFirstLetter = (string) => {
  if (!string) {
    return ''
  }
  const lowerCaseString = string.toLowerCase();
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1);
}