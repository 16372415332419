import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getOperationDescriptionData, genDiuoPDF, getDiuoFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossDIUO from "../../NumDossDIUO";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import "../../../Styles/DIUO/Page4.scss";

const DiuoPage4 = () => { 
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.diuo.index);
  const diuoValue = useSelector((state) => state.diuo.diuoValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [operationDescription, setOperationDescription] = useState();
  const [geographicSituationImg, setGeographicSituationImg] = useState(null);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const loadData = async () => {
    setIsLoading(true);
    try {
      if (!index || !diuoValue) {
        setGeographicSituationImg(null);
        setOperationDescription([]);
        return
      }
      const response = await getOperationDescriptionData(dossierValue, index, diuoValue);
      const data = response.operationDescriptionData.length ? JSON.parse(response.operationDescriptionData) : [];
      const images = response.images;

      if (images.length > 0) {
        images.forEach((image) => {
          const buffer = new Uint8Array(image.img.buffer.data).buffer;
          const blob = new Blob([buffer]);
          const file = new File([blob], image.name, { type: 'image/png' });
          setGeographicSituationImg(file);
        });
      } else setGeographicSituationImg(null);
      setOperationDescription(data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (!dossierValue) return
    loadData()
  }, [diuoValue]);

  const handleSaveData = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('index', index);
    formData.append('diuoValue', diuoValue);
    formData.append('operationDescription', JSON.stringify(operationDescription));

    if (geographicSituationImg) formData.append('geographicSituationImg', geographicSituationImg);

    try {
      const response = await fetch('/api/diuo/saveOperationDescription', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });

      if (!response.ok) {
        console.error('Failed to save');
      }
    } catch (error) {
      console.error('Error update:', error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genDiuoPDF(diuoValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`DIUO`));
  }, [diuoValue]);

  const prevPage = () => {
    history.push("/diuo/page3");
  }

  const nextPage = () => {
    history.push("/diuo/page5");
  }

  return (
    <div className="diuo-page4">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">DIUO (Description de l’opération)</h1>
      <NumDossDIUO />
      <div className="container">
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={2}><strong>Description de l’opération</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td style={{ width: "30%" }}>Adresse/localisation chantier</td>
              <td>
                <input type="text" name="adress" value={operationDescription?.adress || ""} onChange={(e) => setOperationDescription(prevState => ({ ...prevState, adress: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Opération</td>
              <td>
                <input type="text" name="operation" value={operationDescription?.operation || ""} onChange={(e) => setOperationDescription(prevState => ({ ...prevState, operation: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Destination des bâtiments</td>
              <td>
                <input type="text" name="buildingPurpose" value={operationDescription?.buildingPurpose || ""} onChange={(e) => setOperationDescription(prevState => ({ ...prevState, buildingPurpose: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Nombre de bâtiments</td>
              <td>
                <input type="text" name="nbBuilding" value={operationDescription?.nbBuilding || ""} onChange={(e) => setOperationDescription(prevState => ({ ...prevState, nbBuilding: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Nombre de niveaux</td>
              <td>
                <input type="text" name="nbLevels" value={operationDescription?.nbLevels || ""} onChange={(e) => setOperationDescription(prevState => ({ ...prevState, nbLevels: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Surface (m²)</td>
              <td>
                <input type="text" name="surface" value={operationDescription?.surface || ""} onChange={(e) => setOperationDescription(prevState => ({ ...prevState, surface: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>N° Permis de construire</td>
              <td>
                <input type="text" name="constructionPermitNumber" value={operationDescription?.constructionPermitNumber || ""} onChange={(e) => setOperationDescription(prevState => ({ ...prevState, constructionPermitNumber: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Déposé</td>
              <td>
                <input type="date" name="submittedDate" value={operationDescription?.submittedDate || ""} onChange={(e) => setOperationDescription(prevState => ({ ...prevState, submittedDate: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Situation géographique </td>
              <td>
                <input type="text" name="geoLocationText" value={operationDescription?.geoLocationText || ""} onChange={(e) => setOperationDescription(prevState => ({ ...prevState, geoLocationText: e.target.value }))} />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Image</td>
              <td>
                <input
                  type="file"
                  name="geographic-situation-img"
                  key={`img-gs`}
                  multiple
                  accept=".jpg, .jpeg, .png"
                  onChange={(e) => setGeographicSituationImg(e.target.files[0])}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {geographicSituationImg &&
          <div className="img-item">
            <IconButton
              className="item-delete"
              style={{
                color: "white",
                position: "absolute",
                top: 0,
                right: 0
              }}
              onClick={() => setGeographicSituationImg(null)}
            >
              <HighlightOffIcon />
            </IconButton>
            <img
              src={URL.createObjectURL(geographicSituationImg)}
              alt="geographic-situation-img"
              style={{ height: "200px" }}
            />
            <p>Situation géographique</p>
          </div>}
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !diuoValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !diuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !diuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !diuoValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !diuoValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default DiuoPage4;