import React from "react";
import { connect } from "react-redux";
import { newDossierValue } from "../../redux/actions/devisActions";

const FolderMenu = ({ dossier, dossierValue, dispatch }) => {
  const handleDossierChange = (event) => {
    dispatch(newDossierValue(event.target.value));
  };

  return (
    <div className="ndossier">
      <label>N° Dossier : </label>
      <select
        id="lang"
        onChange={handleDossierChange}
        value={dossierValue}
      >
        {dossier.map(({ ndossier }, i) => (
          <option key={i} value={ndossier}>
            {ndossier}
          </option>
        ))}
      </select>
    </div>
  );
};

const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items
});

export default connect(mapStateToProps)(FolderMenu);
