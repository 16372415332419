import React from "react";
import "../../../Styles/RJ/Observations.scss";
import Header from "../../Header/Header";
import DocMenu from '../../DocMenu';
import { IconButton } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import GenPdf from "../../GenPdf";
import { genPDF, getPDF } from "../Utils";
import ObservationTable from "./ObservationTable";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import history from "../../Routes/history";
import RJMenu from "../RJMenu";
import YesNoDialog from "../../YesNoDialog";
import InformationDialog from "../../InformationDialog";
import { fetchRJ } from "../../../redux/actions/rjActions";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import PhotoTable from "./PhotoTable";
import CircularProgress from "@material-ui/core/CircularProgress";

class Observations extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      isMounted: true,
      advancement: 0,
      nbfloor: 0,
      observationsByFloor: [],
      risque: [],
      facteurs: [],
      observations: [],
      employmentLawArray: [],
      floor: 0,
      observationsModified: false,
      openDialogDataChanged: false,
      file: null,
      isOpen: false,
      isDialogOpen: false,
      loading: false,
      open: false,
      anchorEl: null,
      index: "",
      facteursArray: [],
      urlPhotos: [],
      obsArray: [],
      facteurRisque: "",
      images: [],
      disableButton: false,
      loadingImg: false,
      openDialogObs: false,
      openDialogImg: false,
      openDialogDetail: false,
      openDialogNbChar: false,
      nameEmploymentLaw: "",
      detailEmploymentLaw: "",
      indexDel: 0,
      characterCount: 0
    };
    this.changeFloor = this.changeFloor.bind(this);
    this.deletePhoto = this.deletePhoto.bind(this);
    this.handleChangePhoto = this.handleChangePhoto.bind(this);
    this.handleChangeFacteurRisque = this.handleChangeFacteurRisque.bind(this);
    this.delLevel = this.delLevel.bind(this);
    this.levelsWrapper = React.createRef();
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    const { rjValue } = this.props;
    if (!rjValue || !rjValue.observations) {
      this.prevPage();
    } else {
      const parsedObservations = JSON.parse(rjValue.observations);

      if (Array.isArray(parsedObservations) && parsedObservations.length > 0) {
        this.initializeState();
        this.loadObservations(parsedObservations[this.state.floor]);
      } else {
        console.error("Invalid observation data provided in rjValue");
      }
    }
    this.fetchImages()
  }

  componentDidUpdate(prevProps, prevState) {
    const { floor, observations, observationsByFloor, images } = this.state;
    const { rjValue } = this.props;

    if (this.props.rj.length === 0) {
      this.prevPage();
    }

    if (rjValue !== undefined && rjValue !== prevProps.rjValue) {
      this.initializeState();
      this.fetchImages();
    }

    if (floor !== prevState.floor) {
      this.updateStateForFloorChange(floor, observations);
    }

    if (JSON.stringify(observationsByFloor) !== JSON.stringify(observations[floor])) {
      this.updateObservationsByFloor(floor, observations);
    }

    if (observations !== prevState.observations) {
      this.loadPhotos(observations[floor]);
    }

    if (prevState.images.length > 0 && images.length === 0) {
      this.clearImageResources(prevState.images);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    this.clearImageResources(this.state.images);
    this.setState({ isMounted: false });
  }

  clearImageResources = async (images) => {
    await Promise.all(images.map(image => URL.revokeObjectURL(image.src)));
  }

  updateStateForFloorChange(floor, observations) {
    this.setState({
      observationsByFloor: observations[floor],
    });
    this.updatePhotoArray(observations[floor]);
    this.handleChangeFacteurRisque(observations[floor][0]?.title ?? "");
  }

  updateObservationsByFloor(floor, observations) {
    this.setState({
      observationsByFloor:
        observations[floor]?.map((item) => ({ ...item })) ?? "",
    });
    this.updatePhotoArray(observations[floor]);
    this.loadPhotos(observations[floor]);
    this.loadObservations(observations[floor]);
  }

  async initializeState() {
    const { advancement, nbfloor, risque, facteurs, observations } =
      this.props.rjValue;
    const parsedRisque = JSON.parse(risque);
    const parsedFacteurs = JSON.parse(facteurs);
    const parsedObservations = JSON.parse(observations);

    this.setState({ observations: parsedObservations });
    let employmentLawArray = [];
    for (let i = 0; i < parsedObservations.length; i++) {
      const element = parsedObservations[i];
      employmentLawArray.push([]);
      if (element.length > 0) {
        for (let j = 0; j < element.length; j++) {
          const item = element[j];
          const result = await this.handleLoadEmploymentLaw(item.title);
          employmentLawArray[i].push(result);
        }
      }
    }
    this.setState({
      risque: parsedRisque,
      facteurs: parsedFacteurs,
      advancement,
      nbfloor,
      employmentLawArray,
    });
  }

  handleLoadEmploymentLaw(name) {
    var categoryName = name;
    var url = new URL("/api/rj/employmentLawByCategory", window.location.href);
    url.searchParams.append("categoryName", categoryName);

    return fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => data)
      .catch((error) => console.error(error));
  }

  handleClickOpenObs = (index) => {
    this.setState({ openDialogObs: true, indexDel: index });
  };

  handleCloseObs = () => {
    this.setState({ openDialogObs: false });
  };

  handleClickOpenImg = (index) => {
    this.setState({ openDialogImg: true, indexDel: index });
  };

  handleCloseImg = () => {
    this.setState({ openDialogImg: false });
  };

  handleClickOpenDetail = (name, detail) => {
    this.setState({
      openDialogDetail: true,
      nameEmploymentLaw: name,
      detailEmploymentLaw: detail,
    });
  };

  handleCloseDetail = () => {
    this.setState({ openDialogDetail: false });
  };

  handleCloseNbChar = () => {
    this.setState({ openDialogNbChar: false });
  };

  handleCloseDataChanged = () => {
    this.setState({ openDialogDataChanged: false, observationsModified: false });
  };

  updatePhotoArray(array) {
    let newPhotoArray = [{ title: [] }];
    array.map((item) => {
      newPhotoArray[0].title.push(item.title);
    });
    this.setState({ facteursArray: newPhotoArray });
  }

  loadPhotos(array) {
    let newPhoto = [];
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < array.length; j++) {
        for (let k = 0; k < array[j].nfigure.length; k++) {
          if (array[j].nfigure[k] == i + 1) {
            newPhoto.push(array[j].photos[k]);
            break;
          }
        }
      }
    }
    this.setState({ urlPhotos: newPhoto });
  }

  handleChangeFacteurRisque(value) {
    this.setState({ facteurRisque: value });
  }

  handleChangePhoto = (e) => {
    const { obsArray, images, facteurRisque } = this.state;
    const newObsArray = [...obsArray];
    const newImages = [...images];
    const files = e.target.files[0];
    this.addNewImage(files, newObsArray, facteurRisque, newImages);
    this.setState({ obsArray: newObsArray, images: newImages });
  };

  handleCheckNbImg = () => {
    const { floor, observations } = this.state
    let nbPrevImg = 0
    for (let i = 0; i <= floor; i++) {
      for (let j = 0; j < observations[i].length; j++) {
        nbPrevImg += observations[i][j].nfigure.length;
      }
    }
    return nbPrevImg
  }

  addNewImage = (files, obsArray, facteurRisque, images) => {
    const foundObs = obsArray.find((obs) => obs.title === facteurRisque);
    if (foundObs) {
      images.splice(this.handleCheckNbImg(), 0, files)
      foundObs.photos.push(URL.createObjectURL(files));
      foundObs.nfigure.push(this.state.urlPhotos.length + 1);
    }
  };

  loadObservations(array) {
    if (array.length > 0) {
      const obsArray = array.map(({ title, nfigure, photos }) => ({
        title,
        nfigure: [...nfigure],
        photos: [...photos],
      }));
      const firstTitle = obsArray[0].title;
      this.setState({ obsArray, facteurRisque: firstTitle });
    }
  }

  handleNbPrevImg() {
    const { floor, observations } = this.state
    let nbPrevImg = 0
    for (let i = 0; i < floor; i++) {
      for (let j = 0; j < observations[i].length; j++) {
        nbPrevImg += observations[i][j].nfigure.length;
      }
    }
    return nbPrevImg
  }

  async deletePhoto() {
    const { floor, indexDel, observations } = this.state
    this.handleCloseImg();
    let newObservations = JSON.parse(JSON.stringify(observations));

    let idFloorDel = -1;
    let idFigureDel = -1;
    let newArrayImg = [];
    let newImages = [...this.state.images];
    for (let i = 0; i < newObservations[floor].length; i++) {
      for (let j = 0; j < newObservations[floor][i].nfigure.length; j++) {
        if (newObservations[floor][i].nfigure[j] === (indexDel + 1)) {
          idFloorDel = i;
          idFigureDel = j;
        } else if (newObservations[floor][i].nfigure[j] > (indexDel + 1)) {
          newObservations[floor][i].nfigure[j] = newObservations[floor][i].nfigure[j] - 1;
        }
      }
    }
    for (let i = 0; i < newObservations[floor].length; i++) {
      newArrayImg = [];
      newObservations[floor][i].photos.map((item) => {
        newArrayImg.push(item);
      });
      if (i === idFloorDel) {
        newArrayImg.splice(idFigureDel, 1);
        if (floor === 0) {
          newImages.splice(indexDel, 1);
        } else {
          newImages.splice(this.handleNbPrevImg() + indexDel, 1);
        }
      }
      newObservations[floor][i].photos = newArrayImg;
    }
    newObservations[floor][idFloorDel].nfigure.splice(idFigureDel, 1);
    this.setState({ observations: newObservations, images: newImages, observationsModified: true });
  }

  nbPrevImages(floor) {
    const { observations } = this.state
    let count = 0;
    for (let i = floor - 1; i >= 0; i--) {
      for (let j = 0; j < observations[i].length; j++) {
        count += observations[i][j].nfigure.length;
      }
    }
    return count;
  }

  handleChangeObs = (event, index, title) => {
    let newObservations = [...this.state.observations];
    const name = event.target.name
    const value = event.target.value

    if (name === "code_travail") {
      newObservations[this.state.floor][index][name] = value;
      newObservations[this.state.floor][index].observation = title;
    } else if (name === "observation") {
      const nbCarObsByFloor = newObservations[this.state.floor].reduce((acc, curr) => {
        return acc + (curr.observation.length < 70 ? 70 : curr.observation.length)
      }, 0)
      const nbChar = (nbCarObsByFloor - (newObservations[this.state.floor][index][name].length < 70 ? 70 : newObservations[this.state.floor][index][name].length)) + (value.length < 70 ? 70 : value.length)
      if (nbChar > 1730) {
        this.setState({ openDialogNbChar: true })
      } else newObservations[this.state.floor][index][name] = value;
    } else {
      newObservations[this.state.floor][index][name] = value;
    }
    this.setState({ observations: newObservations, observationsModified: true });
  };

  handleChangePhotosArray = (array = this.state.obsArray) => {
    let newObservationsByFloor = [...this.state.observations];
    for (let i = 0; i < newObservationsByFloor[this.state.floor].length; i++) {
      let obs = array.find(
        (item) =>
          item.title === newObservationsByFloor[this.state.floor][i].title
      );
      if (obs) {
        newObservationsByFloor[this.state.floor][i].nfigure = obs.nfigure;
        newObservationsByFloor[this.state.floor][i].photos = obs.photos;
      }
    }
    this.setState({ observations: newObservationsByFloor, observationsModified: true });
  };

  deleteObservation = () => {
    this.handleCloseObs();
    let newImages = [...this.state.images]
    let newEmploymentLawArray = [...this.state.employmentLawArray]

    if (this.state.observationsByFloor[this.state.indexDel].nfigure.length > 0) {
      let imgToDelete = []
      for (let i = 0; i < this.state.observationsByFloor[this.state.indexDel].nfigure.length; i++) {
        imgToDelete.push(`img${this.state.floor}_${this.state.observationsByFloor[this.state.indexDel].nfigure[i] - 1}.jpg`)
      }
      newImages = newImages.filter(img => !imgToDelete.includes(img.name));
    }
    let idFacteurDel = -1;
    let nbFacteurUsed = 0;
    let newFacteurs = [...this.state.facteurs];
    let newRisque = [...this.state.risque];
    let newObservations = [...this.state.observations];

    newFacteurs.find((item, index) => {
      if (
        item.facteur ===
        this.state.observationsByFloor[this.state.indexDel].title
      ) {
        idFacteurDel = index;
      }
    });

    for (let i = 0; i <= this.state.nbfloor; i++) {
      if (newRisque[idFacteurDel + 17 * i].answer) {
        nbFacteurUsed++;
      }
    }
      newRisque[idFacteurDel + 17 * this.state.floor] = {
        answer: false,
        type: "",
      };

    newObservations[this.state.floor].splice(this.state.indexDel, 1);

    let numImgToUpdate = [];
    for (let i = 0; i < newObservations[this.state.floor].length; i++) {
      for (let j = 0; j < newObservations[this.state.floor][i].nfigure.length; j++) {
        numImgToUpdate.push(newObservations[this.state.floor][i].nfigure[j]);
      }
    }

    numImgToUpdate.sort((a, b) => a - b);

    for (let i = 0; i < newObservations[this.state.floor].length; i++) {
      for (let j = 0; j < newObservations[this.state.floor][i].nfigure.length; j++) {
        newObservations[this.state.floor][i].nfigure[j] = numImgToUpdate.indexOf(newObservations[this.state.floor][i].nfigure[j]) + 1;
      }
    }

    newEmploymentLawArray[this.state.floor].splice(this.state.indexDel, 1);
    this.setState({
      facteurs: newFacteurs,
      risque: newRisque,
      observations: newObservations,
      images: newImages,
      employmentLawArray: newEmploymentLawArray
    });
  };

  changeFloor = (floor) => {
    this.setState({ floor });
  };

  handleValidClick = async () => {
    this.setState({ disableButton: true, openDialogDataChanged: false, observationsModified: false });
    const formData = new FormData();

    for (const file of this.state.images) {
      formData.append("images", file);
    }
    formData.append("risque", JSON.stringify(this.state.risque));
    formData.append("observations", JSON.stringify(this.state.observations));
    formData.append("facteurs", JSON.stringify(this.state.facteurs));
    formData.append("ndossier", this.props.dossierValue);
    formData.append("numero", this.props.rjValue.numero);
    const requestOptions = {
      method: "POST",
      body: formData,
    };
    try {
      const res = await fetch("/api/rj/obsUpdate", requestOptions);
      if (res.ok) {
        await this.props.dispatch(fetchRJ(this.props.dossierValue));
      } else {
        throw new Error('Error updating observations');
      }
    } catch (err) {
      console.error(err);
    } finally {
      await this.fetchImages()
      await this.setState({ disableButton: false });
    }
  };

  fetchImages = async () => {
    this.props.dispatch(changePdfFileName(""))
    this.setState({ disableButton: true, loadingImg: false });
    const { dossierValue, rjValue: { numero } } = this.props;
    let images = [];
    try {
      const response = await fetch('/api/rj/getAllImages', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ndossier: dossierValue, numero: numero }),
      });

      if (!response.ok) {
        if (response.status === 204) {
          console.log('No photos found.');
        } else {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
      }

      const contentType = response.headers.get('Content-Type');
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        let newArray0bservations = [...this.state.observations];

        if (data.success) {
          data.images.forEach(image => {
            const arrayBuffer = new Uint8Array(image.img.buffer.data).buffer;
            const blob = new Blob([arrayBuffer]);
            const file = new File([blob], image.name, { lastModified: new Date().getTime(), type: 'text/plain' });
            const url = URL.createObjectURL(blob);
            const indexFloor = image.name[3];
            const numberImg = image.name[5];
            newArray0bservations[indexFloor].map((item) => {
              if (item.nfigure.length > 0) {
                for (let i = 0; i < item.nfigure.length; i++) {
                  if (item.nfigure[i] === +numberImg + 1) {
                    item.photos[i] = url;
                    images.push(file)
                  }
                }
              }
            })
          });
          if (this.state.isMounted) {
            this.setState({ observations: newArray0bservations });
          }
        } else {
          console.error(data.message);
        }
      }
    } catch (error) {
      console.error('There was a problem with the fetch operation: ' + error.message);
    } finally {
      if (this.state.isMounted) {
        this.setState({ disableButton: false, loadingImg: true, images });
      }
    }
  }

  handleGenClick = async () => {
    this.setState({ disableButton: true });
    this.setOpen(true);
    this.setState({ file: null });
    let blob = await genPDF(this.props.dossierValue, this.props.rjValue.numero);
    if (blob) {
      this.setState({ file: blob });
    }
    await this.setState({ disableButton: false });
  };

  handleShowPdf = async () => {
    const { dossierValue, rjValue: { numero } } = this.props;
    this.setState({ disableButton: true });
    this.setOpen(true);
    this.setState({ file: null });
    let response = await getPDF(dossierValue, numero, 'pdf');
    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
    await this.setState({ disableButton: false });
  };

  handleGetPdfFile = async () => {
    const { dossierValue, rjValue: { numero } } = this.props;
    this.setState({ file: null });
    let response = await getPDF(dossierValue, numero, 'pdf');

    if (response.blob.size > 100) {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  };

  async handleGetWordFile() {
    const { dossierValue, rjValue: { numero } } = this.props;
    this.setState({ file: null });
    let response = await getPDF(dossierValue, numero, 'word');

    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      this.setState({ file: response.blob });

      const fileName = response.fileName;
      this.props.dispatch(changePdfFileName(fileName))
    }
  }

  handleLevelClick = (index, type, risk) => {
    const { floor, observations } = this.state;

    const updatedObservations = observations.map((observation, i) => {
      if (i === floor) {
        return observation.map((item, j) => {
          if (j === index) {
            return {
              ...item,
              imported: risk === "imported" ? type : "",
              exported: risk === "exported" ? type : "",
            };
          }
          return item;
        });
      }
      return observation;
    });

    this.setState({ observations: updatedObservations });
    this.hidLevels();
  };

  delLevel(rowIndex, imported) {
    let item = [...this.state.observations];
    if (imported) {
      item[this.state.floor][rowIndex].imported = "";
    } else {
      item[this.state.floor][rowIndex].exported = "";
    }
    this.setState({ observations: item });
    this.hidLevels();
  }

  handleClickOutside = (event) => {
    if (this.levelsWrapper && this.levelsWrapper.current !== null && !this.levelsWrapper.current.contains(event.target)) {
      this.hidLevels();
    }
  };

  showLevels = (event, index) => {
    this.setState({ anchorEl: event.currentTarget, open: true, index: index });
  };
  hidLevels = () => {
    this.setState({ open: false, anchorEl: null });
  };
  setOpen(isOpen) {
    this.setState({ isOpen: isOpen });
  }
  nextPage = () => {
    if (this.state.observationsModified) {
      this.setState({ openDialogDataChanged: true });
    } else history.push("/rj/contacts");
  };
  prevPage = () => {
    if (this.state.observationsModified) {
      this.setState({ openDialogDataChanged: true });
    } else history.push("/rj/evaluation");
  };

  handleChangeTitleName() {
    this.props.dispatch(changeTitleName(`Registre Journal`))
  }
  render() {
    const {
      floor,
      nbfloor,
      open,
      index,
      openDialogObs,
      openDialogImg,
      openDialogDataChanged,
      file,
      isOpen,
      indexDel,
      obsArray,
      advancement,
      observationsByFloor,
      employmentLawArray,
      facteursArray,
      anchorEl,
      urlPhotos,
      disableButton,
      loadingImg,
    } = this.state;

    let buttonFloors = [];
    for (let i = 0; i <= nbfloor; i++) {
      buttonFloors.push(
        <Button
          key={`button-${i}`}
          variant="contained"
          onClick={() => {
            this.changeFloor(i);
          }}
          style={
            floor == i
              ? {
                backgroundColor: "rgb(253, 181, 142)",
                color: "black",
                fontWeight: "bold",
              }
              : {}
          }
        >
          {i === 0 ? `SOUS-SOL / RDC` : `Étage ${i}`}
        </Button>
      );
    }

    return (
      <div className="rj-obs">
        <Header />
        <DocMenu
          file={file}
          getSourceFile={this.handleGetWordFile.bind(this)}
          getPDF={this.handleGetPdfFile.bind(this)}
          handleChangeTitleName={this.handleChangeTitleName.bind(this)}
        />
        <h1 className="title">Registre Journal (Observations)</h1>
        <RJMenu advancement={advancement} handleResetValueArray={""} />
        <div className="data">
          <div className="button-group">{buttonFloors}</div>
          <ObservationTable
            key={observationsByFloor + employmentLawArray}
            observationsByFloor={observationsByFloor}
            employmentLawArray={employmentLawArray}
            floor={floor}
            anchorEl={anchorEl}
            open={open}
            index={index}
            handleLoadEmploymentLaw={this.handleLoadEmploymentLaw}
            handleChangeObs={this.handleChangeObs}
            handleClickOpenObs={this.handleClickOpenObs}
            handleClickOpenDetail={this.handleClickOpenDetail}
            hidLevels={this.hidLevels}
            handleLevelClick={this.handleLevelClick}
            showLevels={this.showLevels}
            delLevel={this.delLevel}
          />
          <PhotoTable
            obsArray={obsArray}
            facteursArray={facteursArray}
            nbImg={urlPhotos.length}
            observationsByFloor={observationsByFloor}
            handleChangePhoto={this.handleChangePhoto}
            handleChangePhotosArray={this.handleChangePhotosArray}
            handleChangeFacteurRisque={this.handleChangeFacteurRisque}
          />
          <div className="img-observation">
            {(urlPhotos.length > 0 && loadingImg) &&
              urlPhotos.map((item, index) => (
                <div className="img-item" key={index}>
                  <IconButton
                    className="item-delete"
                    style={{
                      color: "white",
                      position: "absolute",
                      top: 0,
                      right: 0
                    }}
                    onClick={() => this.handleClickOpenImg(index)}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                  <img
                    src={item}
                    alt={"Figure obs"}
                    style={{ width: "282px", height: "211.5px" }}
                  />
                  <p>Figure {index + 1}</p>
                </div>
              ))}
          </div>
          <div className="button-group">
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleValidClick}
              disabled={disableButton}
            >
              Enregistrer
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleGenClick}
              disabled={disableButton}
            >
              Générer le PDF
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={this.handleShowPdf}
              disabled={disableButton}
            >
              Afficher le PDF
            </Button>
            <Button
              color="primary"
              variant="contained"
              startIcon={<ArrowLeft />}
              onClick={this.prevPage}
              disabled={disableButton}
            >
              Page précedente
            </Button>
            <Button
              color="primary"
              variant="contained"
              endIcon={<ArrowRight />}
              onClick={this.nextPage}
              disabled={disableButton}
            >
              Page suivante
            </Button>
            {disableButton && (
              <CircularProgress className="spinner" color="inherit" />
            )}
          </div>
          <YesNoDialog
            isOpen={openDialogObs}
            title={'SUPPRESSION DE L\'OBSERVATION'}
            question={`Êtes vous sûr de vouloir supprimer l'observation « ${observationsByFloor[indexDel] !== undefined ? observationsByFloor[indexDel].title : ""} » ?`}
            handleYesClick={this.deleteObservation}
            handleClose={this.handleCloseObs}
          />
          <YesNoDialog
            isOpen={openDialogImg}
            title={'SUPPRESSION DE L\'IMAGE'}
            question={`Êtes vous sûr de vouloir supprimer «Figure ${indexDel + 1}» ?`}
            handleYesClick={this.deletePhoto}
            handleClose={this.handleCloseImg}
          />
          <YesNoDialog
            isOpen={openDialogDataChanged}
            title={'DONNEES MODIFIEES'}
            question={`Vous avez apporté des modifications aux observations, voulez-vous les enregistrer ?`}
            handleYesClick={this.handleValidClick}
            handleClose={this.handleCloseDataChanged}
          />
          <InformationDialog
            isOpen={this.state.openDialogDetail}
            title={this.state.nameEmploymentLaw !== "" ? this.state.nameEmploymentLaw : "CODE DU TRAVAIL"}
            information={this.state.detailEmploymentLaw !== "" ? this.state.detailEmploymentLaw : "Aucun code du travail n'a été sélectionné"}
            handleClose={this.handleCloseDetail}
          />
          <InformationDialog
            isOpen={this.state.openDialogNbChar}
            title={"INFORMATION"}
            information={"Vous avez atteint le nombre de caractères autorisés."}
            handleClose={this.handleCloseNbChar}
          />
        </div>
        <GenPdf file={file} isOpen={isOpen} closeDoc={() => this.setOpen(false)} scale={1} pageNumber={2 + floor} />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  rj: state.rj.items,
  rjValue: state.rj.rjValue,
  listFactors: state.rj.listFactors,
  photos: state.rj.photos,
  dossierValue: state.devis.dossierValue,
  dossier: state.dossier.items,
});
export default connect(mapStateToProps)(Observations);
