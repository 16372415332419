import React, { useState, useEffect, useMemo, useRef } from 'react';
import { connect } from "react-redux";
import Header from "../../../Header/Header";
import VICSMenu from "../../VICSMenu";
import DocMenu from "../../../DocMenu";
import GenPdf from "../../../GenPdf";
import InformationDialog from "../../../InformationDialog";
import YesNoDialog from '../../../YesNoDialog';
import history from "../../../Routes/history";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import Delete from "@material-ui/icons/Delete";
import { IconButton } from "@material-ui/core"; 
import Add from "@material-ui/icons/Add";
import ZoomInSharpIcon from "@material-ui/icons/ZoomInSharp";
import { newEntrepriseValue } from "../../../../redux/actions/entrepriseActions";
import { changeVicValue, fetchCompaniesByDossierLot, changeConventionValue } from "../../../../redux/actions/vicActions";
import { changeTitleName, changePdfFileName } from "../../../../redux/actions/emailAction";
import { getQuestionsPage, getObservationData, getVicValues, saveObservationsPage6, getEmploymentLaws, getConventionValue, updateConvention, genVicPDF, getVicPDF } from "../../Utils"
import { v4 as uuidv4 } from "uuid";
import "../../../../Styles/VIC/Page-6.scss";

const Page6 = ({ conventionValue, vicValue, dossierValue, lotsValue, dispatch, entrepriseValue, companies }) => {
  const prevDossierValue = useRef(dossierValue);
  const prevLotsValue = useRef(lotsValue);
  const prevCompanies = useRef(companies);
  const prevEntrepriseValue = useRef(entrepriseValue);
  const [disableButton, setDisableButton] = useState(false);
  const [page4data, setPage4data] = useState([]);
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [openDialogConv, setOpenDialogConv] = useState(false);
  const [openDialogAddConv, setOpenDialogAddConv] = useState(false);
  const [openDialogDelConv, setOpenDialogDelConv] = useState(false);
  const [convention, setConvention] = useState('');
  const [currentLaborCodeArticle, setCurrentLaborCodeArticle] = useState('');
  const [currentLaborCodeArticleDescription, setCurrentLaborCodeArticleDescription] = useState('');
  const [employmentLawArray, setEmploymentLawArray] = useState([]);
  const [observations, setObservations] = useState({
    page1_answers: [],
    page2_answers: [],
    page3_answers: [],
    page4_answers: null,
    page6_answers: null
  });

  useEffect(() => {
    const fetchData = async () => {
      if (dossierValue !== prevDossierValue.current || lotsValue !== prevLotsValue.current) {
        await dispatch(fetchCompaniesByDossierLot(dossierValue, lotsValue));
        prevDossierValue.current = dossierValue;
        prevLotsValue.current = lotsValue;
      }
    };

    fetchData();
  }, [dossierValue, lotsValue, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (companies !== prevCompanies.current) {
        if (companies.length > 0) {
          await dispatch(newEntrepriseValue(companies[0]));
          const result = await getVicValues(dossierValue, lotsValue, companies[0]);
          await dispatch(changeVicValue(result.id));
        } else {
          await dispatch(changeVicValue(""));
        }
        prevCompanies.current = companies;
      }

      if (entrepriseValue !== prevEntrepriseValue.current) {
        const result = await getVicValues(dossierValue, lotsValue, entrepriseValue);
        await dispatch(changeVicValue(result.id));
        prevEntrepriseValue.current = entrepriseValue;
      }
    };

    fetchData();
  }, [companies, entrepriseValue, dispatch]);

  useEffect(async () => {
    const page1Ids = Object.values(observations.page1_answers)
      .filter(answer => answer.observation)
      .map(answer => answer.id);

    const gatherIdsFromPages = (pages) =>
      pages.flatMap(page =>
        page.questions.some(question => question.observation) ? [page.id] : []
      );

    const page2Ids = gatherIdsFromPages(observations.page2_answers);
    const page3Ids = gatherIdsFromPages(observations.page3_answers);
    const page4Ids = observations.page4_answers ? gatherIdsFromPages(observations.page4_answers) : [];

    const observationIdArray = [...page1Ids, ...page2Ids, ...page3Ids, ...page4Ids];

    if (observationIdArray.length > 0) {
      setEmploymentLawArray(await getEmploymentLaws(observationIdArray));
    }
  }, [observations]);

  useEffect(() => {
    if (!vicValue) {
      setEmploymentLawArray([]);
      setObservations({ page1_answers: [], page2_answers: [], page3_answers: [], page4_answers: null, page6_answers: null });
      return
    }
    setDisableButton(true);
    const fetchObservationData = async () => {
      dispatch(changePdfFileName(""))
      await getQuestionsPage(4, vicValue).then((data) => {
        setPage4data(data !== null ? data : []);
      })
      try {
        const data = await getObservationData(vicValue);
        setObservations(formatData(data));
        const convention = await getConventionValue(vicValue);
        dispatch(changeConventionValue(convention));
      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    };
    fetchObservationData();
    setDisableButton(false);
  }, [vicValue]);

  const formatAnswer = (answer, num, title) => ({
    id: title === 'Entreprise (PPSPS)' ? 1 : title === 'Travaux (Sous-Traitants)' ? 2 : 3,
    answer: answer.answer,
    observation: answer.observation,
    num: answer.num ? answer.num : num,
    observationDate: answer.observationDate ? answer.observationDate : '',
    title: answer.title ? answer.title : title,
    observationDetails: answer.observationDetails ? answer.observationDetails : '',
    laborCodeArticle: answer.laborCodeArticle ? answer.laborCodeArticle : '',
    expectedOn: answer.expectedOn ? answer.expectedOn : '',
  });

  const formatQuestions = (questions, pageNum) => {
    let count = 1;
    return questions.map((category) => ({
      ...category,
      questions: category.questions.map((question) => {
        if (question.observation) {
          return {
            ...question,
            num: `${pageNum}-${count++}`,
            observationDate: question.observationDate ? question.observationDate : '',
            title: question.title ? question.title : `${category.cat_question} (${question.question})`,
            observationDetails: question.observationDetails ? question.observationDetails : '',
            laborCodeArticle: question.laborCodeArticle ? question.laborCodeArticle : '',
            expectedOn: question.expectedOn ? question.expectedOn : '',
          };
        }
        return question;
      }),
    }));
  };

  const formatData = (data) => {
    try {
      const page1Answers = JSON.parse(data.page1_answers);
      const page2Answers = JSON.parse(data.page2_answers);
      const page3Answers = JSON.parse(data.page3_answers);
      const page4Answers = data.page4_answers === null ? [] : JSON.parse(data.page4_answers);
      const page6Answers = data.page6_answers ? data.page6_answers : [];

      let count = 1;
      const titles = {
        ppsps: "Entreprise (PPSPS)",
        subcontractor: "Travaux (Sous-Traitants)",
        pgc: "Travaux (PGC)",
      };

      Object.keys(titles).forEach((key) => {
        if (page1Answers[key].observation) {
          page1Answers[key] = formatAnswer(page1Answers[key], `1-${count++}`, titles[key]);
        }
      });

      const formattedData = {
        page1_answers: page1Answers,
        page2_answers: formatQuestions(page2Answers, 2),
        page3_answers: formatQuestions(page3Answers, 3),
        page4_answers: formatQuestions(page4Answers, 4),
        page6_answers: page6Answers
      };

      return formattedData;
    } catch (error) {
      console.error("Erreur lors du formatage des données:", error);
      return {
        page1_answers: [],
        page2_answers: [],
        page3_answers: [],
        page4_answers: null
      };
    }
  };

  const handleChangePage1Values = (event, item, title) => {
    const { name, value } = event.target;

    setObservations(prevObservations => ({
      ...prevObservations,
      page1_answers: {
        ...prevObservations.page1_answers,
        [item]: {
          ...prevObservations.page1_answers[item],
          [name]: value,
          observationDetails: name === "laborCodeArticle" ? title : name === "observationDetails" ? value : prevObservations.page1_answers[item].observationDetails
        }
      }
    }));
  }

  const handleDeleteFirstPageObservation = (item) => {
    switch (item) {
      case 'ppsps':
        setObservations(prevObservations => ({
          ...prevObservations,
          page1_answers: {
            ...prevObservations.page1_answers,
            ppsps: {
              answer: prevObservations.page1_answers.ppsps.answer,
              observation: false,
              observationDetails: '',
              expectedOn: '',
              laborCodeArticle: '',
              observationDate: '',
              num: ''
            },
            subcontractor: prevObservations.page1_answers.subcontractor.observation ? {
              ...prevObservations.page1_answers.subcontractor,
              num: prevObservations.page1_answers.subcontractor.num ? prevObservations.page1_answers.subcontractor.num.replace(/-(\d+)$/, (match, number) => `-${parseInt(number, 10) - 1}`) : '',
            } : { answer: "0", observation: false },
            pgc: prevObservations.page1_answers.pgc.observation ? {
              ...prevObservations.page1_answers.pgc,
              num: prevObservations.page1_answers.pgc.num.replace(/-(\d+)$/, (match, number) => `-${parseInt(number, 10) - 1}`),
            } : { answer: "0", observation: false }
          }
        }))
        break;
      case 'subcontractor':
        setObservations(prevObservations => ({
          ...prevObservations,
          page1_answers: {
            ...prevObservations.page1_answers,
            subcontractor: {
              answer: prevObservations.page1_answers.subcontractor.answer,
              observation: false,
              observationDetails: '',
              expectedOn: '',
              laborCodeArticle: '',
              observationDate: '',
              num: ''
            },
            pgc: prevObservations.page1_answers.pgc.observation ? {
              ...prevObservations.page1_answers.pgc,
              num: prevObservations.page1_answers.pgc.num.replace(/-(\d+)$/, (match, number) => `-${parseInt(number, 10) - 1}`),
            } : { answer: "0", observation: false }
          }
        }));
        break;
      default:
        break;
    }
  }

  const firstPage = useMemo(() => {
    const data = observations.page1_answers
    if (!data) return null
    return (
      <>
        {
          data.ppsps?.observation &&
          <tr key={data.ppsps.num}>
            <td>{data.ppsps.num}</td>
            <td>
              <input
                type='date'
                value={data.ppsps.observationDate}
                style={{ width: '118px' }}
                name="observationDate"
                onChange={(e) => handleChangePage1Values(e, 'ppsps')}
              />
            </td>
            <td>{data.ppsps.title}</td>
            <td>
              <textarea
                value={data.ppsps.observationDetails}
                style={{ width: '280px' }}
                name="observationDetails"
                onChange={(e) => handleChangePage1Values(e, 'ppsps')}
              />
            </td>
            <td>
              {
                employmentLawArray.filter((element) => element.category_id === 1).length > 0 ? (
                  <div style={{ display: "flex" }}>
                    <select
                      name="laborCodeArticle"
                      style={{
                        width: "100%",
                        pointerEvents: "visible",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                      value={observations.page1_answers.ppsps.laborCodeArticle}
                      onChange={(e) => handleChangePage1Values(e, 'ppsps', e.target.value !== '' ? employmentLawArray.find(item => item.name === e.target.value).title : '')}
                    >
                      <option value=""></option>
                      {employmentLawArray.filter((element) => element.category_id === 1).map((item, index) => {
                        if (observations.page1_answers.ppsps.laborCodeArticle === item.name) {
                          return (
                            <option value={item.name} key={uuidv4()} >
                              {item.name}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item.name} key={uuidv4()}>
                              {item.title}
                            </option>
                          );
                        }
                      })}
                    </select>
                    <ZoomInSharpIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const selectedItem = employmentLawArray.find(item => item.name === observations.page1_answers.ppsps.laborCodeArticle);
                        const name = selectedItem ? selectedItem.name : '';
                        const detail = selectedItem ? selectedItem.detail : '';
                        handleShowLaborCodeArticle(name, detail);
                      }}
                    />
                  </div>
                ) : (
                  <input
                    type="text"
                    name="laborCodeArticle"
                    style={{
                      pointerEvents: "visible",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                    onChange={(e) => handleChangePage1Values(e, 'ppsps')}
                  />
                )
              }
            </td>

            <td>
              <input
                type='date'
                value={data.ppsps.expectedOn}
                style={{ width: '118px' }}
                name="expectedOn"
                onChange={(e) => handleChangePage1Values(e, 'ppsps')}
              />
            </td>
            <td>
              <IconButton
                aria-label="delete"
                size="small"
                className="delete"
                onClick={() => handleDeleteFirstPageObservation('ppsps')}
              >
                <Delete />
              </IconButton>
            </td>
          </tr>
        }
        {
          data.subcontractor?.observation &&
          <tr key={data.subcontractor.num}>
            <td>{data.subcontractor.num}</td>
            <td>
              <input
                type='date'
                value={data.subcontractor.observationDate}
                style={{ width: '118px' }}
                name="observationDate"
                onChange={(e) => handleChangePage1Values(e, 'subcontractor')}
              />
            </td>
            <td>{data.subcontractor.title}</td>
            <td>
              <textarea
                value={data.subcontractor.observationDetails}
                style={{ width: '280px' }}
                name="observationDetails"
                onChange={(e) => handleChangePage1Values(e, 'subcontractor')}
              />
            </td>
            <td>
              {
                employmentLawArray.filter((element) => element.category_id === 2).length > 0 ? (
                  <div style={{ display: "flex" }}>
                    <select
                      name="laborCodeArticle"
                      style={{
                        width: "100%",
                        pointerEvents: "visible",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                      value={observations.page1_answers.subcontractor.laborCodeArticle}
                      onChange={(e) => handleChangePage1Values(e, 'subcontractor', e.target.value !== '' ? employmentLawArray.find(item => item.name === e.target.value).title : '')}
                    >
                      <option value=""></option>
                      {employmentLawArray.filter((element) => element.category_id === 2).map((item, index) => {
                        if (observations.page1_answers.subcontractor.laborCodeArticle === item.name) {
                          return (
                            <option value={item.name} key={uuidv4()}>
                              {item.name}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item.name} key={uuidv4()}>
                              {item.title}
                            </option>
                          );
                        }
                      })}
                    </select>
                    <ZoomInSharpIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const selectedItem = employmentLawArray.find(item => item.name === observations.page1_answers.subcontractor.laborCodeArticle);
                        const name = selectedItem ? selectedItem.name : '';
                        const detail = selectedItem ? selectedItem.detail : '';
                        handleShowLaborCodeArticle(name, detail);
                      }}
                    />
                  </div>
                ) : (
                  <input
                    type="text"
                    name="laborCodeArticle"
                    style={{
                      pointerEvents: "visible",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                    onChange={(e) => handleChangePage1Values(e, 'subcontractor')}
                  />
                )
              }
            </td>
            <td>
              <input
                type='date'
                value={data.subcontractor.expectedOn}
                style={{ width: '118px' }}
                name="expectedOn"
                onChange={(e) => handleChangePage1Values(e, 'subcontractor')}
              />
            </td>
            <td>
              <IconButton
                aria-label="delete"
                size="small"
                className="delete"
                onClick={() => handleDeleteFirstPageObservation('subcontractor')}
              >
                <Delete />
              </IconButton>
            </td>
          </tr>
        }
        {
          data.pgc?.observation &&
          <tr key={data.pgc.num}>
            <td>{data.pgc.num}</td>
            <td>
              <input
                type='date' value={data.pgc.observationDate}
                style={{ width: '118px' }}
                name="observationDate"
                onChange={(e) => handleChangePage1Values(e, 'pgc')}
              />
            </td>
            <td>{data.pgc.title}</td>
            <td>
              <textarea
                value={data.pgc.observationDetails}
                style={{ width: '280px' }}
                name="observationDetails"
                onChange={(e) => handleChangePage1Values(e, 'pgc')}
              />
            </td>
            <td>
              {
                employmentLawArray.filter((element) => element.category_id === 3).length > 0 ? (
                  <div style={{ display: "flex" }}>
                    <select
                      name="laborCodeArticle"
                      style={{
                        width: "100%",
                        pointerEvents: "visible",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                      value={observations.page1_answers.pgc.laborCodeArticle}
                      onChange={(e) => handleChangePage1Values(e, 'pgc', e.target.value !== '' ? employmentLawArray.find(item => item.name === e.target.value).title : '')}
                    >
                      <option value=""></option>
                      {employmentLawArray.filter((element) => element.category_id === 3).map((item, index) => {
                        if (observations.page1_answers.pgc.laborCodeArticle === item.name) {
                          return (
                            <option value={item.name} key={uuidv4()}>
                              {item.name}
                            </option>
                          );
                        } else {
                          return (
                            <option value={item.name} key={uuidv4()}>
                              {item.title}
                            </option>
                          );
                        }
                      })}
                    </select>
                    <ZoomInSharpIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        const selectedItem = employmentLawArray.find(item => item.name === observations.page1_answers.pgc.laborCodeArticle);
                        const name = selectedItem ? selectedItem.name : '';
                        const detail = selectedItem ? selectedItem.detail : '';
                        handleShowLaborCodeArticle(name, detail);
                      }}
                    />
                  </div>
                ) : (
                  <input
                    type="text"
                    name="laborCodeArticle"
                    style={{
                      pointerEvents: "visible",
                      textAlign: "center",
                      fontSize: "14px",
                    }}
                    onChange={(e) => handleChangePage1Values(e, 'pgc')}
                  />
                )
              }
            </td>
            <td>
              <input
                type='date'
                value={data.pgc.expectedOn}
                style={{ width: '118px' }}
                name="expectedOn"
                onChange={(e) => handleChangePage1Values(e, 'pgc')}
              />
            </td>
            <td>
              <IconButton
                aria-label="delete"
                size="small"
                className="delete"
                onClick={() => setObservations(prevObservations => ({
                  ...prevObservations,
                  page1_answers: {
                    ...prevObservations.page1_answers,
                    pgc: {
                      answer: prevObservations.page1_answers.pgc.answer,
                      observation: false,
                      observationDetails: '',
                      expectedOn: '',
                      laborCodeArticle: '',
                      observationDate: '',
                      num: ''
                    }
                  }
                }))}
              >
                <Delete />
              </IconButton>
            </td>
          </tr>
        }
      </>
    )
  }, [observations.page1_answers, employmentLawArray]);

  const handleChangeValues = (event, category, questionNum, page, title) => {
    const { name, value } = event.target;

    setObservations(prevObservations => ({
      ...prevObservations,
      [`page${page}_answers`]: prevObservations[`page${page}_answers`].map((pageAnswer) => {
        if (pageAnswer.cat_question === category) {
          return {
            ...pageAnswer,
            questions: pageAnswer.questions.map((question) => {
              if (question.num === questionNum) {
                return {
                  ...question,
                  [name]: value,
                  observationDetails: (name === 'laborCodeArticle' && title) ? title : name === 'observationDetails' ? value : question.observationDetails
                };
              }
              return question;
            })
          };
        }
        return pageAnswer;
      })
    }));
  }

  const deleteObservation = (page, questionNum) => {
    let updatedPageAnswers = JSON.parse(JSON.stringify(observations[`page${page}_answers`]));

    const questionIndex = updatedPageAnswers.findIndex(answer => {
      return answer.questions.some(question => question.num === questionNum);
    });

    if (questionIndex !== -1) {
      const updatedQuestions = updatedPageAnswers[questionIndex].questions.map(question => {
        if (question.num === questionNum) {
          return { question: question.question, answer: question.answer, observation: false, observationDetails: '', expectedOn: '', laborCodeArticle: '', observationDate: '', num: '' }
        } else {
          return question;
        }
      });

      updatedPageAnswers[questionIndex] = { ...updatedPageAnswers[questionIndex], questions: updatedQuestions };

      for (let i = 0; i < updatedPageAnswers.length; i++) {
        for (let j = 0; j < updatedPageAnswers[i].questions.length; j++) {
          const currentQuestion = updatedPageAnswers[i].questions[j];

          if (currentQuestion.observation) {
            const [numDeletedPrefix, numDeletedSuffix] = questionNum.split('-');
            const [prefix, suffix] = currentQuestion.num.split('-');
            const currentSuffix = parseInt(suffix);

            if (currentSuffix > numDeletedSuffix) {
              const newNumSuffix = currentSuffix - 1;
              updatedPageAnswers[i].questions[j].num = `${prefix}-${newNumSuffix}`;
            }
          }
        }
      }

      setObservations(prevState => ({
        ...prevState,
        [`page${page}_answers`]: updatedPageAnswers
      }));
    }
  };



  const otherPages = useMemo(() => {
    let pageCode = [];

    for (let numPage = 2; numPage < 5; numPage++) {
      let pageAnswers;
      switch (numPage) {
        case 2:
          pageAnswers = observations.page2_answers;
          break;
        case 3:
          pageAnswers = observations.page3_answers;
          break;
        case 4:
          pageAnswers = observations.page4_answers === null ? [] : observations.page4_answers;
          break;
        default:
          pageAnswers = [];
          break;
      }

      for (let i = 0; i < pageAnswers.length; i++) {
        for (let j = 0; j < pageAnswers[i].questions.length; j++) {
          if (pageAnswers[i].questions[j].observation) {
            pageCode.push(
              <tr key={`${pageAnswers[i].questions[j].num}`}>
                <td>{pageAnswers[i].questions[j].num}</td>
                <td>
                  <input
                    type='date'
                    value={observations[`page${numPage}_answers`][i].questions[j].observationDate}
                    style={{ width: '118px' }}
                    name="observationDate"
                    onChange={(e) => handleChangeValues(e, observations[`page${numPage}_answers`][i].cat_question, observations[`page${numPage}_answers`][i].questions[j].num, numPage)}
                  />
                </td>
                <td>{pageAnswers[i].questions[j].title}</td>
                <td>
                  <textarea
                    value={pageAnswers[i].questions[j].observationDetails}
                    name="observationDetails"
                    style={{ width: '280px' }}
                    onChange={(e) => handleChangeValues(e, pageAnswers[i].cat_question, pageAnswers[i].questions[j].num, numPage)}
                  />
                </td>
                <td>
                  {
                    employmentLawArray.filter((element) => element.category_id === pageAnswers[i].id).length > 0 ? (
                      <div style={{ display: "flex" }}>
                        <select
                          name="laborCodeArticle"
                          style={{
                            width: "100%",
                            pointerEvents: "visible",
                            textAlign: "center",
                            fontSize: "14px",
                          }}
                          value={pageAnswers[i].questions[j].laborCodeArticle}
                          onChange={(e) => handleChangeValues(e, pageAnswers[i].cat_question, pageAnswers[i].questions[j].num, numPage, e.target.value !== '' ? employmentLawArray.find(item => item.name === e.target.value).title : '')}
                        >
                          <option value=""></option>
                          {employmentLawArray.filter((element) => element.category_id === pageAnswers[i].id).map((item, index) => {
                            if (pageAnswers[i].questions[j].laborCodeArticle === item.name) {
                              return (
                                <option value={item.name} key={uuidv4()}>
                                  {item.name}
                                </option>
                              );
                            } else {
                              return (
                                <option value={item.name} key={uuidv4()}>
                                  {item.title}
                                </option>
                              );
                            }
                          })}
                        </select>
                        <ZoomInSharpIcon
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            const selectedItem = employmentLawArray.find(item => item.name === pageAnswers[i].questions[j].laborCodeArticle);
                            const name = selectedItem ? selectedItem.name : '';
                            const detail = selectedItem ? selectedItem.detail : '';
                            handleShowLaborCodeArticle(name, detail);
                          }}
                        />
                      </div>
                    ) : (
                      <input
                        type="text"
                        name="laborCodeArticle"
                        style={{
                          width: '140px',
                          textAlign: "center",
                          fontSize: "14px",
                        }}
                        value={pageAnswers[i].questions[j].laborCodeArticle}
                        onChange={(e) => handleChangeValues(e, pageAnswers[i].cat_question, pageAnswers[i].questions[j].num, numPage)}
                      />
                    )
                  }
                </td>
                <td>
                  <input
                    type='date'
                    value={pageAnswers[i].questions[j].expectedOn}
                    style={{ width: '118px' }}
                    name="expectedOn"
                    onChange={(e) => handleChangeValues(e, pageAnswers[i].cat_question, pageAnswers[i].questions[j].num, numPage)}
                  />
                </td>
                <td>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    className="delete"
                    onClick={() => deleteObservation(numPage, pageAnswers[i].questions[j].num)}
                  >
                    <Delete />
                  </IconButton>
                </td>
              </tr>
            );
          }
        }
      }
    }
    return pageCode;
  }, [observations.page2_answers, observations.page3_answers, observations.page4_answers, employmentLawArray])

  const handleAddPage6Observations = () => {
    setObservations(prevState => ({
      ...prevState,
      page6_answers: [...prevState.page6_answers, {
        expectedOn: "",
        laborCodeArticle: "",
        num: prevState.page6_answers.length + 1,
        observation: true,
        observationDate: "",
        observationDetails: "",
        title: ""
      }]
    }));
  }

  const handleChangeLastPageValues = (event, num) => {
    const { name, value } = event.target;
    let updateLastPageAnswers = [...observations.page6_answers];

    for (let i = 0; i < updateLastPageAnswers.length; i++) {
      if (updateLastPageAnswers[i].num === num) {
        updateLastPageAnswers[i][name] = value;
      }
    }

    setObservations(prevState => ({
      ...prevState,
      page6_answers: updateLastPageAnswers
    }));
  }

  const deleteLastPageObservation = (num) => {
    const numDeleteObs = typeof num === 'string' ? +num : num;
    let updateLastPageAnswers = [...observations.page6_answers];

    for (let i = 0; i < updateLastPageAnswers.length; i++) {
      if (updateLastPageAnswers[i].num === numDeleteObs) {
        updateLastPageAnswers.splice(i, 1);
      }
    }

    for (let i = 0; i < updateLastPageAnswers.length; i++) {
      if (updateLastPageAnswers[i].num > numDeleteObs) {
        updateLastPageAnswers[i].num -= 1;
      }
    }

    setObservations(prevState => ({
      ...prevState,
      page6_answers: updateLastPageAnswers
    }));
  }

  const lastPage = useMemo(() => {
    return (
      <>
        {observations.page6_answers && observations.page6_answers.map((page, index) => {
          return (
            <tr key={index}>
              <td>{page.num}</td>
              <td>
                <input
                  type='date'
                  value={page.observationDate}
                  style={{ width: '118px' }}
                  name="observationDate"
                  onChange={(e) => handleChangeLastPageValues(e, page.num)}
                />
              </td>
              <td>
                <input
                  type='text'
                  value={page.title}
                  style={{ width: '240px' }}
                  name="title"
                  onChange={(e) => handleChangeLastPageValues(e, page.num)}
                />
              </td>
              <td>
                <textarea
                  value={page.observationDetails}
                  name="observationDetails"
                  style={{ width: '280px' }}
                  onChange={(e) => handleChangeLastPageValues(e, page.num)}
                />
              </td>
              <td>
                <input
                  type='text'
                  value={page.laborCodeArticle}
                  style={{
                    width: '140px',
                    textAlign: "center",
                    fontSize: "14px",
                  }}
                  name="laborCodeArticle"
                  onChange={(e) => handleChangeLastPageValues(e, page.num)}
                />
              </td>
              <td>
                <input
                  type='date'
                  value={page.expectedOn}
                  style={{ width: '118px' }}
                  name="expectedOn"
                  onChange={(e) => handleChangeLastPageValues(e, page.num)}
                />
              </td>
              <td>
                <IconButton
                  aria-label="delete"
                  size="small"
                  className="delete"
                  onClick={() => deleteLastPageObservation(page.num)}
                >
                  <Delete />
                </IconButton>
              </td>
            </tr>
          )
        })}
      </>
    );
  }, [observations.page6_answers])

  const handleValidClick = async () => {
    setDisableButton(true);
    try {
      const response = await saveObservationsPage6(vicValue, observations);
      if (!response.ok) {
        throw new Error('Failed to save observations');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    setDisableButton(false);
  }

  const handleShowLaborCodeArticle = (laborCodeArticle, laborCodeArticleDescription) => {
    setCurrentLaborCodeArticle(laborCodeArticle === '' ? 'CODE DU TRAVAIL' : laborCodeArticle);
    setCurrentLaborCodeArticleDescription(laborCodeArticleDescription === '' ? 'Aucun code du travail n\'a été sélectionné' : laborCodeArticleDescription);
    setOpenDialog(true);
  }

  const handleAddConv = async () => {
    setDisableButton(true);
    try {
      const response = await updateConvention(vicValue, convention);
      if (!response.ok) {
        throw new Error("Failed to save observations");
      }
      dispatch(changeConventionValue(convention))
      setOpenDialogAddConv(false)
    } catch (error) {
      console.error("Failed to save observations:", error.message);
    }
    setDisableButton(false);
  };

  const handleDelConv = async () => {
    setDisableButton(true);
    try {
      const response = await updateConvention(vicValue, '');
      if (!response.ok) {
        throw new Error("Failed to save observations");
      }
      dispatch(changeConventionValue(''))
      setOpenDialogDelConv(false)
    } catch (error) {
      console.error("Failed to save observations:", error.message);
    }
    setDisableButton(false);
  };

  const handleCloseDialogConv = () => {
    setOpenDialogConv(false);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setCurrentLaborCodeArticle('');
    setCurrentLaborCodeArticleDescription('');
  }

  const handleGenClick = async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genVicPDF(vicValue);
    if (blob) {
      setFile(blob);
      setIsOpen(true);
    }
    setDisableButton(false);
  }

  const handleShowPdf = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');
    
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);

      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetPdfFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetWordFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
    }
    setDisableButton(false);
  }

  const prevPage = () => {
    history.push("/vic/page-5");
  };

  const nextPage = () => {
    history.push("/vic/page-7");
  };

  const validationPage = () => {
    history.push("/vic/validations");
  };

  const handleChangeTitleName = () => {
    dispatch(changeTitleName(`VIC`));
  };

  return (
    <div className="page6">
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Visite d'Inspection Commune (Page 6)</h1>
      <VICSMenu />
      <div className="data-page6">
        <table className="observation-table">
          <thead>
            <tr>
              <th style={{ width: '115px' }}>Numéro d'observation</th>
              <th style={{ width: '130px' }}>Date d'observation</th>
              <th style={{ width: '280px' }}>Titre</th>
              <th style={{ width: '290px' }}>Observation</th>
              <th style={{ width: '180px' }}>Article du code du travail</th>
              <th style={{ width: '130px' }}>Prévu-le</th>
              <th style={{ width: '45px', backgroundColor: 'transparent', borderRight: 'none', borderTop: 'none' }}></th>
            </tr>
          </thead>
          <tbody>
            {firstPage || <React.Fragment />}
            {otherPages || <React.Fragment />}
            {lastPage || <React.Fragment />}
            {vicValue ? (
              <tr>
                <td className="add-observation" style={{ backgroundColor: 'transparent', border: 'none' }}>
                  <IconButton
                    aria-label="add"
                    size="small"
                    style={{ backgroundColor: "transparent" }}
                    onClick={() => handleAddPage6Observations()}
                  >
                    <Add />
                  </IconButton>
                </td>
              </tr>
            ) : <React.Fragment />}
          </tbody>
        </table>
        <div className="buttons-container">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleValidClick()}
            disabled={vicValue === "" || disableButton}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleGenClick()}
            disabled={vicValue === "" || disableButton}
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleShowPdf()}
            disabled={vicValue === "" || disableButton}
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setOpenDialogConv(true)}
            disabled={vicValue === "" || disableButton}
          >
            Convention
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={prevPage}
            disabled={disableButton}
          >
            Page précedente
          </Button>
          {conventionValue !== "" &&
            <Button
              color="primary"
              variant="contained"
              endIcon={<ArrowRight />}
              onClick={nextPage}
              disabled={disableButton}
            >
              Page suivante
            </Button>}
          {!conventionValue &&
            <Button
              color="primary"
              variant="contained"
              endIcon={<ArrowRight />}
              onClick={validationPage}
              disabled={disableButton}
            >
              Validation
            </Button>}
          {disableButton && <CircularProgress className="spinner" color="inherit" />}
        </div>
      </div>
      <Dialog open={openDialogConv} onClose={handleCloseDialogConv}>
        <DialogTitle style={{ textAlign: "center" }}>
          AJOUT - CONVENTION
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Quel type de convention voulez-vous ajouter ou modifier à la VIC ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={() => {
              setConvention('Echafaudage')
              setOpenDialogConv(false)
              setOpenDialogAddConv(true)
            }}
            style={{ color: "white", backgroundColor: conventionValue === "Echafaudage" ? "" : "#FF7F50" }}
            disabled={conventionValue === "Echafaudage"}
          >
            Echafaudage
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setConvention('Grue')
              setOpenDialogConv(false)
              setOpenDialogAddConv(true)
            }}
            style={{ color: "white", backgroundColor: conventionValue === "Grue" ? "" : "#FF7F50" }}
            disabled={conventionValue === "Grue"}
          >
            Grue
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              setOpenDialogConv(false)
              setOpenDialogDelConv(true)
            }}
            style={{ color: "white", backgroundColor: conventionValue === "" ? "" : "red" }}
            disabled={conventionValue === ""}
          >
            Supprimer
          </Button>
          <Button
            variant="contained"
            onClick={() => handleCloseDialogConv()}
            style={{ color: "white", backgroundColor: "#F5B039" }}
          >
            Annuler
          </Button>
        </DialogActions>
      </Dialog>
      <YesNoDialog
        isOpen={openDialogAddConv}
        title={'CONVENTION'}
        question={`Voulez-vous ajouter la convention «${convention}» à la VIC ?`}
        handleYesClick={() => handleAddConv()}
        handleClose={() => {
          setConvention('')
          setOpenDialogAddConv(false)
        }}
      />
      <YesNoDialog
        isOpen={openDialogDelConv}
        title={'CONVENTION'}
        question={`Voulez-vous supprimer la convention «${conventionValue}» de la VIC ?`}
        handleYesClick={() => handleDelConv()}
        handleClose={() => {
          setConvention('')
          setOpenDialogDelConv(false)
        }}
      />
      <InformationDialog
        isOpen={openDialog}
        title={currentLaborCodeArticle}
        information={currentLaborCodeArticleDescription}
        handleClose={() => handleCloseDialog()}
      />
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};
const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  conventionValue: state.vic.conventionValue,
  vicValue: state.vic.vicValue,
  lotsValue: state.vic.lotsValue,
  companies: state.vic.companies,
  entrepriseValue: state.entreprise.entrepriseValue,
});

export default connect(mapStateToProps)(Page6);
