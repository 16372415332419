import { useEffect, useState, useRef, useMemo } from "react";
import { useSelector } from "react-redux";
import Header from "../../Header/Header";
import NumDoss from './NumDoss';
import GenPdf from "../../GenPdf";
import { format } from 'date-fns';
import { Button } from "@material-ui/core";
import LoadingOverlay from "../../LoadingOverlay";
import pdf_logo from "../ContratValid/pdf_logo.svg";
import "../../../Styles/Contrat/EchValidation.scss";

const EchValidation = () => {
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const amendment = useSelector((state) => state.echeances.amendment);
  const echeance = useSelector((state) => state.echeances.item);

  const fileInputRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [signedEcheance, setSignedEcheance] = useState('');
  const [isSigned, setIsSigned] = useState(false);
  const [signedDate, setSignedDate] = useState("");
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);


  const loadData = async () => {
    if (Object.keys(echeance).length === 0) {
      initialiseValues();
      return;
    }

    setIsLoading(true);
    setIsOpen(false);

    try {
      const response = await fetch(`/api/contrat/getEcheanceValidationSigned/${dossierValue}/${amendment}`);

      if (!response.ok) {
        initialiseValues();
      } else {
        const data = await response.json();

        setSignedEcheance(data.signed_echeance);
        setIsSigned(data.validation);
        setSignedDate(data.signing_date ? format(data.signing_date, 'yyyy-MM-dd') : '');
        setFile(null);

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [echeance]);

  const dates = useMemo(() => {
    return echeance?.dates ? JSON.parse(echeance.dates) : [];
  }, [echeance]);

  const initialiseValues = () => {
    setIsSigned(false);
    setSignedDate('');
    setFile(null);
    setIsOpen(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const handleChangeSigned = (event) => {
    const { name, checked, value, files } = event.target;
    if (name === "signed") {
      setIsSigned(checked);
      if (!checked) {
        setSignedDate('');
      }
    } else if (name === "signed-file") {
      setFile(files[0] || null);
      if (files[0]) {
        setIsSigned(true);
      }
    } else {
      setSignedDate(value);
      if (value) {
        setIsSigned(true);
      }
    }
  }

  const handleSaveData = async () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('amendment', amendment);
    formData.append('isSigned', isSigned ? 1 : 0);
    formData.append('signedDate', signedDate);

    if (file) formData.append('echeanceFile', file);

    try {
      const response = await fetch('/api/contrat/updateEcheance', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      loadData();
    }
  }

  const handleShowPdf = async () => {
    setIsLoading(true);
    setFile(null);

    try {
      const response = await fetch(`/api/contrat/getSignedEcheance/${dossierValue}/${amendment}`);
      const blob = await response.blob();
      setFile(blob);
      setIsOpen(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const emptyEcheance = Object.keys(echeance).length === 0;

  return (
    <div className="echeances-valid">
      {isLoading && <LoadingOverlay />}
      <Header />
      <h1 className="title">Validation de l'avenant échéances</h1>
      <NumDoss />
      <div className="container">
        <table className="custom-table">
          <thead className="custom-cell-head">
            <tr>
              <th colSpan="2">AVENANT ÉCHÉANCES SIGNÉ</th>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td>Dossier</td>
              <td>
                <span> {dossierValue} </span>
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Avenant</td>
              <td>
                <span> {emptyEcheance ? '' : amendment} </span>
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Envoi PGC</td>
              <td>
                <span> {echeance?.datepgce ? format(echeance.datepgce, 'dd/MM/yyyy') : ''} </span>
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Démarrage</td>
              <td>
                <span> {echeance?.datedem ? format(echeance.datedem, 'dd/MM/yyyy') : ''} </span>
              </td>
            </tr>
            <tr className="custom-cell custom-cell-dates">
              <td>Echéances</td>
              <td>
                {dates.length > 4 ? (
                  <>
                    {dates.slice(0, 3).map((item, i) => (
                      <span key={i}>{item.dateech ? format(item.dateech, 'dd/MM/yyyy') : ''}</span>
                    ))}
                    ...
                    <span>{dates[dates.length - 1].dateech ? format(dates[dates.length - 1].dateech, 'dd/MM/yyyy') : ''}</span>
                  </>
                ) : (
                  dates.map((item, i) => (
                    <span key={i}>{item.dateech ? format(item.dateech, 'dd/MM/yyyy') : ''}</span>
                  ))
                )}

              </td>
            </tr>
            <tr className="custom-cell">
              <td>Signé</td>
              <td>
                <input
                  type="checkbox"
                  name="signed"
                  checked={isSigned || false}
                  onChange={(event) => handleChangeSigned(event)}
                  style={{ accentColor: isSigned ? 'green' : '' }}
                  disabled={emptyEcheance}
                />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Date de signature</td>
              <td>
                <input
                  type="date"
                  name="signed-date"
                  value={signedDate || ''}
                  onChange={(event) => handleChangeSigned(event)}
                  disabled={emptyEcheance}
                />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Contrat signé</td>
              <td>
                <input
                  type="file"
                  name="signed-file"
                  multiple
                  accept=".pdf"
                  onChange={(event) => handleChangeSigned(event)}
                  ref={fileInputRef}
                  disabled={emptyEcheance}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {!emptyEcheance && signedEcheance && (
          <div className="img-container">
            <img
              src={pdf_logo}
              alt="pdf-shadow"
              className="img-shadow"
            />

            <img
              src={pdf_logo}
              alt="pdf-img"
              className="img-main"
              onClick={() => handleShowPdf()}
            />

            <p>Avenant signé</p>
          </div>
        )}

        <div className="buttons-container">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSaveData()}
            disabled={emptyEcheance}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleShowPdf()}
            disabled={emptyEcheance || !signedEcheance}
          >
            Afficher le PDF
          </Button>
        </div>
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default EchValidation;
