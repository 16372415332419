import React, { useState, useReducer, useEffect, useCallback, useRef } from "react";
import { connect } from "react-redux";
import Header from "../../../Header/Header";
import VICSMenu from "../../VICSMenu";
import DocMenu from "../../../DocMenu";
import GenPdf from "../../../GenPdf";
import history from "../../../Routes/history";
import { changeTitleName, changePdfFileName } from "../../../../redux/actions/emailAction";
import { changeVicValue, fetchCompaniesByDossierLot, changeConventionValue } from "../../../../redux/actions/vicActions";
import { newEntrepriseValue } from "../../../../redux/actions/entrepriseActions";
import { getQuestionsPage, getVicValues, getConventionValue, getDossierInfos, saveInformationsPage7, genVicPDF, getVicPDF } from "../../Utils"
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../../Styles/VIC/Page-7.scss";

const titles = { 
  Grue: [
    {
      title: "Grue à Tour",
      value: false,
    }, {
      title: "Monte-Charge",
      value: false,
    }, {
      title: "Treuil",
      value: false,
    }, {
      title: "Ascenseur",
      value: false,
    }, {
      title: "Grue Mobile",
      value: false,
    }
  ],
  Echafaudage: [
    {
      title: "Echafaudage(s) tubulaires(s)",
      value: false,
    }, {
      title: "Echafaudage(s) roulant(s)",
      value: false,
    }, {
      title: "Plate(s)-formes suspendue(s) motorisée(s)",
      value: false,
    }, {
      title: "Plate(s)-forme(s) suspendue(s) mue(s) à bras",
      value: false,
    }, {
      title: "Plate(s)-forme(s) sur mât(s)",
      value: false,
    }, {
      title: "Plate(s)-forme(s) suspendue(s) mue(s) à bras",
      value: false,
    }
  ]
}

const fieldNames = {
  ownerCompany: "Entreprise Propriétaire",
  ownerCompanyAddress: "Adresse Entreprise Propriétaire",
  userCompany: "Entreprise Utilisatrice",
  userCompanyAddress: "Adresse Entreprise Utilisatrice",
  clientCompany: "Entreprise Cliente",
  clientCompanyAddress: "Adresse du Client",
};

const workNames = {
  vmc: "VMC",
  heating: "Chauffage",
  waterproofing: "Etanchéité",
  electricity: "Electricité",
  plumbing: "Plomberie",
  exteriorCarpentry: "Menuiserie Extérieure",
  interiorCarpentry: "Menuiserie Intérieure",
  others: "Autres...",
};

const initialState = {
  ownerCompany: "",
  ownerCompanyAddress: "",
  userCompany: "",
  userCompanyAddress: "",
  clientCompany: "",
  clientCompanyAddress: "",
  conventionPresentationDate: "",
  usageDuration: "",
  operationNature: "",
  vmc: false,
  heating: false,
  waterproofing: false,
  electricity: false,
  plumbing: false,
  exteriorCarpentry: false,
  interiorCarpentry: false,
  others: false,
};

const formReducer = (state, action) => {
  switch (action.type) {
    case "CHANGE_VALUE":
      return {
        ...state,
        [action.payload.name]: action.payload.value,
      };
    default:
      return state;
  }
};

const Page7 = ({ conventionValue, dispatch, vicValue, dossierValue, lotsValue, companies, entrepriseValue }) => {
  const [disableButton, setDisableButton] = useState(false);
  const [page4data, setPage4data] = useState([]);
  const [convTitles, setConvTitles] = useState(conventionValue !== "" ? titles[conventionValue] : []);
  const [dossier, setDossier] = useState({});
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const isFirstRender = useRef(true);
  const prevDossierValue = useRef(dossierValue);
  const prevLotsValue = useRef(lotsValue);
  const prevCompanies = useRef(companies);
  const prevEntrepriseValue = useRef(entrepriseValue);
  const [formState, dispatchForm] = useReducer(formReducer, initialState);

  useEffect(() => {
    if (isFirstRender.current) return
    setDisableButton(true);
    const fetchData = async () => {
      if (dossierValue !== prevDossierValue.current || lotsValue !== prevLotsValue.current) {
        await dispatch(fetchCompaniesByDossierLot(dossierValue, lotsValue));
        prevDossierValue.current = dossierValue;
        prevLotsValue.current = lotsValue;
      }
    };

    fetchData();
    setDisableButton(false);
  }, [dossierValue, lotsValue]);

  useEffect(() => {
    if (isFirstRender.current) return
    setDisableButton(true);

    const fetchData = async () => {
      if (companies !== prevCompanies.current) {
        if (companies.length > 0) {
          await dispatch(newEntrepriseValue(companies[0]));
          const result = await getVicValues(dossierValue, lotsValue, companies[0]);
          await dispatch(changeVicValue(result.id));
        } else {
          await dispatch(changeVicValue(""));
        }
        prevCompanies.current = companies;
      }
    };

    fetchData();
    setDisableButton(false);
  }, [companies]);

  useEffect(() => {
    if (isFirstRender.current) return
    setDisableButton(true);

    const fetchData = async () => {
      if (entrepriseValue !== prevEntrepriseValue.current) {
        const result = await getVicValues(dossierValue, lotsValue, entrepriseValue);
        await dispatch(changeVicValue(result.id));
        prevEntrepriseValue.current = entrepriseValue;
      }
    };

    fetchData();
    setDisableButton(false);
  }, [entrepriseValue]);

  useEffect(() => {
    const fetchData = async () => {
      if (!vicValue) return dispatch(changeConventionValue(''));

      setDisableButton(true);
      dispatch(changePdfFileName(""))
      const convention = await getConventionValue(vicValue);
      dispatch(changeConventionValue(convention));
      if (convention === "") history.push("/vic/page-6");

      await getQuestionsPage(4, vicValue).then((data) => {
        setPage4data(data !== null ? data : []);
      })

      const dossierInfos = await getDossierInfos(dossierValue);
      setDossier(dossierInfos);

      const data = await getQuestionsPage(7, vicValue);

      if (data.conv_titles !== "") {
        const updateTitles = JSON.parse(data.conv_titles);
        const informations = JSON.parse(data.informations);

        setConvTitles(updateTitles);
        Object.keys(informations).forEach((item) => {
          dispatchForm({ type: "CHANGE_VALUE", payload: { name: item, value: informations[item] } });
        });
      } else {
        setConvTitles(convention !== "" ? titles[convention] : []);
        Object.keys(initialState).forEach((item) => {
          dispatchForm({ type: "CHANGE_VALUE", payload: { name: item, value: initialState[item] } });
        })
      };

    }
    fetchData();
    setDisableButton(false);

    isFirstRender.current = false;
  }, [vicValue]);

  const handleChangeValue = useCallback((e) => {
    dispatchForm({
      type: "CHANGE_VALUE",
      payload: {
        name: e.target.name,
        value: e.target.type === "checkbox" ? e.target.checked : e.target.value,
      },
    });
  }, []);

  const handleValidClick = async () => {
    setDisableButton(true);
    try {
      const response = await saveInformationsPage7(vicValue, convTitles, formState);
      if (!response.ok) {
        throw new Error('Failed to save observations');
      }
    } catch (error) {
      console.error('Error:', error);
    }
    setDisableButton(false);
  }

  const handleGenClick = async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genVicPDF(vicValue);
    if (blob) {
      setFile(blob);
      setIsOpen(true);
    }
    setDisableButton(false);
  }

  const handleShowPdf = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');

    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);

      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetPdfFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetWordFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
    }
    setDisableButton(false);
  }

  const prevPage = () => {
    history.push("/vic/page-6");
  };

  const nextPage = () => {
    history.push("/vic/page-8");
  };

  const handleChangeTitleName = () => {
    dispatch(changeTitleName(`VIC`));
  };

  return (
    <div className="page7">
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Visite d'Inspection Commune (Page 7)</h1>
      <VICSMenu />
      <div className="data-page7">
        <div className="title-conv">
          <h3>CONVENTION D’UTILISATION ENTRE 2 ENTREPRISES</h3>
          <p>Equipement de travail temporaire en hauteur ou de levage</p>
        </div>
        <table className="custom-table">
          <tbody>
            {convTitles.map((item, index) => (
              <tr className="custom-cell" key={`conv-title-${index}`}>
                <td className="custom-cell-check">
                  <input
                    name="answer"
                    type="checkbox"
                    checked={item.value}
                    onChange={() =>
                      setConvTitles(prev =>
                        prev.map((title, i) =>
                          i === index ? { ...title, value: !title.value } : title
                        )
                      )
                    }
                  />
                </td>
                <td className="custom-cell-label">
                  <label>{item.title}</label>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div style={{ width: "558px" }}>
          <p>Cette convention d'utilisation est mise en place sur le chantier de : {dossier.ville_op}, {dossier.adresse_op}, {dossier.cp_op}</p>
        </div>
        <table className="custom-table-companies">
          <tbody>
            {Object.entries(fieldNames).map(([fieldName, fieldLabel], index) => (
              <tr key={`field-name-${index}`}>
                <td>{fieldLabel}</td>
                <td>
                  <input
                    type="text"
                    name={fieldName}
                    value={formState[fieldName]}
                    onChange={handleChangeValue}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td>Date Présentation Convention</td>
              <td>
                <input
                  type="date"
                  name="conventionPresentationDate"
                  className="date"
                  value={formState.conventionPresentationDate}
                  onChange={handleChangeValue}
                />
              </td>
            </tr>
            <tr>
              <td>Durée d'utilisation</td>
              <td>
                <input
                  type="time"
                  name="usageDuration"
                  value={formState.usageDuration}
                  onChange={handleChangeValue}
                />
              </td>
            </tr>
            <tr>
              <td>Nature des opérations</td>
              <td>
                <input
                  type="text"
                  name="operationNature"
                  value={formState.operationNature}
                  onChange={handleChangeValue}
                />
              </td>
            </tr>
          </tbody>
        </table>
        <table className="custom-table">
          <thead>
            <tr className="works">
              <td colSpan="2"><h3>Travaux à réaliser</h3></td>
            </tr>
          </thead>
          <tbody>
            {Object.entries(workNames).map(([workName, workLabel], index) => (
              <tr className="custom-cell" key={`work-name-${index}`}>
                <td className="custom-cell-check">
                  <input
                    type="checkbox"
                    name={workName}
                    checked={formState[workName]}
                    onChange={handleChangeValue}
                  />
                </td>
                <td className="custom-cell-label">{workLabel}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="buttons-container">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleValidClick()}
            disabled={vicValue === "" || disableButton}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleGenClick()}
            disabled={vicValue === "" || disableButton}
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleShowPdf()}
            disabled={vicValue === "" || disableButton}
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={prevPage}
            disabled={disableButton}
          >
            Page précedente
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={nextPage}
            disabled={disableButton}
          >
            Page suivante
          </Button>
          {disableButton && <CircularProgress className="spinner" color="inherit" />}
        </div>
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
        pageNumber={page4data.length > 0 ? 7 : 6}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  conventionValue: state.vic.conventionValue,
  dossierValue: state.devis.dossierValue,
  vicValue: state.vic.vicValue,
  lotsValue: state.vic.lotsValue,
  companies: state.vic.companies,
  entrepriseValue: state.entreprise.entrepriseValue,
});

export default connect(mapStateToProps)(Page7);
