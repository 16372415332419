import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getConstructionElementsData, genPreDiuoPDF, getPreDiuoFile } from "../Utils";
import { CONSTRUCTION_ELEMENTS } from './Const';
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossPreDiuo from "../../NumDossPreDiuo";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import ConstructionElements from "./ConstructionElements";
import "../../../Styles/PreDIUO/Page3.scss";

const PreDiuoPage3 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.preDiuo.index);
  const preDiuoValue = useSelector((state) => state.preDiuo.preDiuoValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);
  const [constructionDataLoaded, setConstructionDataLoaded] = useState({
    selectedElements: {},
    elementsData: {}
  });
  const [constructionData, setConstructionData] = useState({
    selectedElements: {},
    elementsData: {}
  });
  const [error, setError] = useState(null);

  const loadData = async () => {
    try {
      if (!preDiuoValue) {
        const defaultData = {
          selectedElements: Object.fromEntries(CONSTRUCTION_ELEMENTS.map(element => [element, false])),
          elementsData: {}
        };
        setConstructionDataLoaded(defaultData);
        setConstructionData(defaultData);
        return;
      }

      setIsLoading(true);
      const response = await getConstructionElementsData(preDiuoValue);

      const completeData = {
        selectedElements: Object.keys(response.selectedElements).length > 0 ? JSON.parse(response.selectedElements) : Object.fromEntries(CONSTRUCTION_ELEMENTS.map(element => [element, false])),
        elementsData: Object.keys(response.elementsData).length > 0 ? JSON.parse(response.elementsData) : {}
      };

      setConstructionDataLoaded(completeData);
      setConstructionData(completeData);
    } catch (error) {
      console.error('Error loading construction data:', error);
      setError('Failed to load construction data');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSaveData = async () => {
    try {
      setError(null);
      setDisableButton(true);
      setIsLoading(true);

      const completeData = {
        selectedElements: Object.entries(constructionData.selectedElements)
          .reduce((acc, [element, value]) => {
            if (CONSTRUCTION_ELEMENTS.includes(element) || value === true) {
              acc[element] = value;
            }
            return acc;
          }, Object.fromEntries(CONSTRUCTION_ELEMENTS.map(element => [element, false]))),
        elementsData: constructionData.elementsData
      };

      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          preDiuoValue,
          constructionElements: completeData
        }),
      };

      const response = await fetch("/api/prediuo/updateConstructionElements", requestOptions);
      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Erreur lors de la sauvegarde");
      }

    } catch (err) {
      console.error("Erreur:", err);
      setError(err.message);
    } finally {
      setIsLoading(false);
      setDisableButton(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [preDiuoValue]);

  // Gestionnaire de changement des données
  const handleConstructionDataChange = useCallback((newData) => {
    setConstructionData(newData);
    setError(null); // Réinitialise les erreurs quand les données changent
  }, []);

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genPreDiuoPDF(preDiuoValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPreDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPreDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPreDiuoFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [preDiuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`Pré DIUO`));
  }, [preDiuoValue]);

  return (
    <div className="pre-diuo-page3">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Pré DIUO (Les éléments de construction)</h1>
      <NumDossPreDiuo />

      {error && (
        <div className="error-message">
          {error}
        </div>
      )}

      <div className="container">
        <ConstructionElements
          onDataChange={handleConstructionDataChange}
          constructionData={constructionDataLoaded}
        />
      </div>

      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !preDiuoValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !preDiuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleShowPdf}
          disabled={disableButton || !preDiuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={() => history.push("/prediuo/page2")}
          disabled={disableButton || !preDiuoValue}
        >
          Page précédente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={() => history.push("/prediuo/validation")}
          disabled={disableButton || !preDiuoValue}
        >
          Validation
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>

      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default PreDiuoPage3;