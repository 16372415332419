import React from "react";
import Button from "@material-ui/core/Button";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from '@material-ui/core/Typography';
import Paper from "@material-ui/core/Paper";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../Styles/FactureAmendment/FacAmendmentReminderDetail.scss";
import { numberToFormat } from "../../Utils";

class FacAmendmentReminderDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  handleColorEchDate = (validation, first_reminder, dateString) => {
    const today = new Date();
    const dateFormated = new Date(dateString);
    const reminderInterval = first_reminder ? 15 : 30;
    dateFormated.setDate(dateFormated.getDate() + reminderInterval);

    if (validation === 1) {
      return "green"; 
    }

    return today >= dateFormated ? (first_reminder ? "orange" : "red") : "";
  };

  formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${day} / ${month} / ${year}`;
  };

  formatNfacture = (nfacture) => {
    const [split1, split2] = nfacture.split(" ")
    return `${split1} A-${split2}`
  }

  render() {
    const { first_reminder, maitreOuvrage, dataFacture, clientResponse, comptableSexe, comptableName, disableButton } = this.props
    const dataLoaded = Object.keys(dataFacture).length > 1
    const PaidInvoice = dataFacture.validation === 1 ? true : false

    return (
      <div className="reminder-detail">
        {dataLoaded && <TableContainer className="table-data" component={Paper}>
          <Table aria-label="simple table">
            <TableHead className="table-head">
              <TableRow className="head-row">
                <TableCell className="MuiTableCell-head" colSpan={2}>
                  Détail Facture
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell className="first-column-cell">Code Client:</TableCell>
                <TableCell>
                  <Typography name="codeclient">{dataFacture?.numclient}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>N° de Facture:</TableCell>
                <TableCell>
                  <Typography name="numfacture">{dataFacture.nfacture ? this.formatNfacture(dataFacture.nfacture) : ""}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nom du Responsable:</TableCell>
                <TableCell>
                  <Typography name="nomresponsable">{maitreOuvrage.prenom !== undefined ? maitreOuvrage.prenom + " " + maitreOuvrage.nom : ""}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Adresse Mail:</TableCell>
                <TableCell>
                  <Typography name="adressemail">{maitreOuvrage.mail}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nom de l'Entreprise:</TableCell>
                <TableCell>
                  <Typography name="nomentreprise">{dataFacture.dossier?.sci_sccv}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Adresse:</TableCell>
                <TableCell>
                  <Typography name="adresse">{maitreOuvrage.adress}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Code Postal:</TableCell>
                <TableCell>
                  <Typography name="codepostal">{maitreOuvrage.cp}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Ville:</TableCell>
                <TableCell>
                  <Typography name="ville">{maitreOuvrage.city}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date Échéance:</TableCell>
                <TableCell>
                  <Typography name="date_ech" style={{ fontWeight: "bold", fontSize: "18px", color: this.handleColorEchDate(dataFacture.validation, first_reminder, dataFacture.date_ech) }}>
                    {this.formatDate(dataFacture.date_ech)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Date Relance:</TableCell>
                <TableCell>
                  <input type="date" name="date_reminder" value={dataFacture.date_reminder || ""} onChange={(event) => this.props.handleDateReminderChange(event.target.value)} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Montant HT:</TableCell>
                <TableCell>
                  <Typography name="montantht">{dataFacture.total_ht ? numberToFormat(dataFacture.total_ht) : 0} €</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Montant TVA:</TableCell>
                <TableCell>
                  <Typography name="montanttva">{dataFacture.total_tva ? numberToFormat(dataFacture.total_tva) : 0} €</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Montant TTC:</TableCell>
                <TableCell>
                  <Typography name="montantht">{dataFacture.total_ttc ? numberToFormat(dataFacture.total_ttc) : 0} €</Typography>
                </TableCell>
              </TableRow>
              {!first_reminder &&
                <>
                  <TableRow>
                    <TableCell>Réponse Client:</TableCell>
                    <TableCell >
                      <label className="client-response-radio">
                        <input type="radio" name="clientResponse" value="oui" onChange={() => this.props.handleChangeClientResponse(true)} /> Oui
                      </label>
                      <label>
                        <input type="radio" name="clientResponse" value="non" defaultChecked onChange={() => this.props.handleChangeClientResponse(false)} /> Non
                      </label>
                    </TableCell>
                  </TableRow>
                  {clientResponse &&
                    <>
                      <TableRow>
                        <TableCell>Comptable genre:</TableCell>
                        <TableCell>
                          <select
                            name="comptableSexe"
                            style={{ marginLeft: "-4px", width: "150px" }}
                            value={comptableSexe}
                            onChange={(event) => this.props.handleChangeCompatbleInfos(event)}
                          >
                            <option value="male">Homme</option>
                            <option value="female">Femme</option>
                          </select>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Comptable:</TableCell>
                        <TableCell>
                          <input
                            type="text"
                            name="comptableName"
                            style={{ marginLeft: "-4px", textAlign: "left" }}
                            placeholder="&quot;Prénom NOM&quot;"
                            value={comptableName}
                            onChange={(event) => this.props.handleChangeCompatbleInfos(event)}
                          />
                        </TableCell>
                      </TableRow>
                    </>
                  }
                </>
              }
              <TableRow>
                <TableCell className="last-row-cell" colSpan={2}>
                  <Button variant="contained" color="primary" onClick={this.props.handleGenFacAmendmentReminder} disabled={disableButton || PaidInvoice}>Générer Relance</Button>
                  <Button variant="contained" color="primary" onClick={this.props.handleShowFacAmendmentReminder} disabled={disableButton}>Afficher Relance</Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>}
        <div className="btn-group">
          {(disableButton || !dataLoaded) && (
            <CircularProgress className="spinner" color="inherit" />
          )}
          <Button variant="contained" color="primary" onClick={this.props.handleSaveData} disabled={disableButton || PaidInvoice || !dataLoaded}>Enregistrer</Button>
          <Button variant="contained" color="primary" onClick={this.props.handleGenFacAmendmentReminderTable} disabled={disableButton || !dataLoaded}>Générer Tableau</Button>
          <Button variant="contained" color="primary" onClick={this.props.handleShowReminderTablePDF} disabled={disableButton || !dataLoaded}>Afficher Tableau</Button>
          <Button variant="contained" color="primary" onClick={() => this.props.handleDateReminderChange(new Date().toLocaleDateString('en-CA'))} disabled={disableButton || !dataLoaded}>Annuler</Button>
        </div>
      </div>
    )
  }
}

export default FacAmendmentReminderDetail;