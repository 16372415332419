import React, { useState, useEffect, useMemo, useCallback, memo } from 'react';
import { ResponsiveContainer, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import { normalizeCity, capitalizeFirstLetter } from './Utils';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const { dossier, progress, operation } = payload[0].payload;
    return (
      <div
        style={{
          background: 'rgba(255,255,255,0.9)',
          border: '1px solid #ccc',
          padding: '20px',
          textAlign: 'left'
        }}
      >
        <p>
          <strong>Dossier:</strong> {dossier}
        </p>
        <p>
          <strong>Opération:</strong> {capitalizeFirstLetter(operation)}
        </p>
        <p>
          <strong>Avancement:</strong> {progress}%
        </p>
        <p>
          <strong>Ville:</strong> {label}
        </p>
      </div>
    );
  }
  return null;
};

const CustomBar = (props) => {
  const { x, y, width, height, fill, index, activeIndex } = props;
  const currentFill = index === activeIndex ? '#ff7f50' : fill;
  return <rect x={x} y={y} width={width} height={height} fill={currentFill} />;
};

const Chart = memo(() => {
  const [progressingByCity, setProgressingByCity] = useState([]);
  const [filterStatus, setFilterStatus] = useState("en-cours");

  const fetchProgressingByCity = useCallback(async () => {
    try {
      const response = await fetch('/api/progressingData');
      const data = await response.json();
      setProgressingByCity(data);
    } catch (error) {
      console.error('Error fetching progressing data:', error);
    }
  }, []);

  useEffect(() => {
    fetchProgressingByCity();
  }, [fetchProgressingByCity]);

  const groupedData = useMemo(() => {
    const grouped = progressingByCity.reduce((acc, item) => {
      const normCity = normalizeCity(item.city);
      if (!acc[normCity]) {
        acc[normCity] = [];
      }
      acc[normCity].push({ ...item, city: normCity });
      return acc;
    }, {});

    Object.keys(grouped).forEach(city => {
      if (filterStatus === "termine") {
        grouped[city] = grouped[city].filter(item => item.progress === 100 && item.abandoned === 0);
      } else if (filterStatus === "en-cours") {
        grouped[city] = grouped[city].filter(item => item.progress !== 100 && item.abandoned === 0);
      } else if (filterStatus === "sans-suite") {
        grouped[city] = grouped[city].filter(item => item.abandoned === 1);
      }

      grouped[city].sort((a, b) => Number(a.dossier) - Number(b.dossier));
    });

    Object.keys(grouped).forEach(city => {
      if (grouped[city].length === 0) {
        delete grouped[city];
      }
    });

    const sortedCities = Object.keys(grouped).sort();

    const sortedGrouped = {};
    sortedCities.forEach(city => {
      sortedGrouped[city] = grouped[city];
    });

    return sortedGrouped;
  }, [progressingByCity, filterStatus]);

  const cities = useMemo(() => Object.keys(groupedData), [groupedData]);

  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const totalPages = Math.ceil(cities.length / itemsPerPage);

  useEffect(() => {
    if (currentPage >= totalPages && totalPages > 0) {
      setCurrentPage(0);
    }
  }, [totalPages, currentPage]);

  const currentCities = useMemo(() => cities.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  ), [cities, currentPage, itemsPerPage]);

  const [selectedDossiers, setSelectedDossiers] = useState({});

  useEffect(() => {
    setSelectedDossiers(
      cities.reduce((acc, city) => {
        acc[city] = 0;
        return acc;
      }, {})
    );
  }, [cities]);

  const [hoveredBarIndex, setHoveredBarIndex] = useState(null);

  const chartData = useMemo(() => {
    return currentCities.map((city) => {
      const selectedIndex = selectedDossiers[city] || 0;
      const dossierData = groupedData[city][selectedIndex] || { progress: 0, dossier: 'N/A' };
      return {
        city,
        operation: dossierData.operation,
        progress: dossierData.progress,
        dossier: dossierData.dossier,
      };
    });
  }, [currentCities, selectedDossiers, groupedData]);

  const handleDossierChange = useCallback((city, index) => {
    setSelectedDossiers((prev) => ({
      ...prev,
      [city]: index,
    }));
  }, []);

  const handleFilterChange = useCallback((e) => {
    setFilterStatus(e.target.value);
    setCurrentPage(0);
  }, []);

  const CustomXAxisTick = (props) => {
    const { x, y, payload } = props;
    const city = payload.value;
    const dossiers = groupedData[city] || [];
    const selectedIndex = selectedDossiers[city] || 0;

    const maxItemsPerColumn = 5;
    const rowHeight = 15;
    const colWidth = 30;
    const cityY = 15;
    const numColumns = Math.ceil(dossiers.length / maxItemsPerColumn);
    const offset = ((numColumns - 1) * colWidth) / 2;

    return (
      <g transform={`translate(${x}, ${y})`}>
        <text
          x={0}
          y={cityY}
          textAnchor="middle"
          fill="#666"
          style={{ fontSize: '16px' }}
        >
          {city}
        </text>
        {dossiers.map((dossier, index) => {
          const col = Math.floor(index / maxItemsPerColumn);
          const row = index % maxItemsPerColumn;
          return (
            <text
              key={dossier.dossier}
              x={col * colWidth - offset}
              y={cityY + rowHeight * (row + 1)}
              textAnchor="middle"
              fill={selectedIndex === index ? '#8884d8' : '#aaa'}
              style={{
                cursor: 'pointer',
                fontWeight: selectedIndex === index ? 'bold' : 'normal'
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleDossierChange(city, index);
              }}
            >
              {dossier.dossier}
            </text>
          );
        })}
      </g>
    );
  };

  const getStatusMessage = () => {
    if (cities.length === 0) {
      if (filterStatus === "termine") {
        return "Aucun dossier Archivé n'a été trouvé.";
      } else if (filterStatus === "en-cours") {
        return "Aucun dossier en cours n'a été trouvé.";
      } else if (filterStatus === "sans-suite") {
        return "Aucun dossier sans suite n'a été trouvé.";
      }
    }
    return null;
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <ResponsiveContainer width="100%" height={300}>
        <BarChart
          data={chartData}
          margin={{ top: 20, right: 20, bottom: 70, left: 20 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="city" tick={<CustomXAxisTick />} />
          <YAxis domain={[0, 100]} />
          <Tooltip content={<CustomTooltip />} />
          <Bar
            dataKey="progress"
            fill="#8884d8"
            barSize={120}
            onMouseEnter={(data, index) => setHoveredBarIndex(index)}
            onMouseLeave={() => setHoveredBarIndex(null)}
            shape={(barProps) => (
              <CustomBar {...barProps} activeIndex={hoveredBarIndex} />
            )}
          />
        </BarChart>
      </ResponsiveContainer>

      <div style={{ marginTop: '10px', display: 'flex', alignItems: 'center', gap: '10px' }}>
        <button
          onClick={() => setCurrentPage(currentPage - 1)}
          disabled={currentPage === 0}
          style={{ fontSize: '14px', padding: '5px 10px', borderRadius: '4px' }}
        >
          Précédent
        </button>

        <select
          value={filterStatus}
          onChange={handleFilterChange}
          style={{
            fontSize: '14px',
            padding: '5px 10px',
            borderRadius: '4px',
            backgroundColor: filterStatus === 'en-cours' ? '#FF7F50' : filterStatus === 'termine' ? '#4CAF50' : '#F44336',
            color: 'white',
          }}
        >
          <option value="en-cours">En cours</option>
          <option value="termine">Archivé</option>
          <option value="sans-suite">Sans suite</option>
        </select>
        <button
          onClick={() => setCurrentPage(currentPage + 1)}
          disabled={currentPage === totalPages - 1 || totalPages === 0}
          style={{ fontSize: '14px', padding: '5px 18px', borderRadius: '4px' }}
        >
          Suivant
        </button>
      </div>

      {getStatusMessage() && (
        <div style={{ color: '#666', marginTop: '10px' }}>
          {getStatusMessage()}
        </div>
      )}
    </div>
  );
});

export default Chart;