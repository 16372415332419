import React, { useState } from 'react';
import { IconButton } from "@material-ui/core";
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import moment from "moment";
import { format } from 'date-fns';
moment.locale('fr');

const ContactList = ({ appointments, setIsConfirmationAppointment, setIntervenant, setSendEmail }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filters, setFilters] = useState({
    toContact: false,
    contactSoon: false,
    toConfirm: false
  });

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleFilterChange = (filterName) => {
    setFilters(prev => ({
      ...prev,
      [filterName]: !prev[filterName]
    }));
    setCurrentPage(1);
  };

  const filteredAppointments = appointments.filter(item => {
    const today = moment(new Date());
    const mailSentDate = item.mail_sent_date ? moment(item.mail_sent_date) : null;
    const differenceInDays = mailSentDate ? today.diff(mailSentDate, 'days') : 0;

    if (!filters.toContact && !filters.contactSoon && !filters.toConfirm) {
      return true;
    }

    return (
      (filters.toContact && !item.appointment_start && mailSentDate && differenceInDays >= 15) ||
      (filters.contactSoon && !item.appointment_start && mailSentDate && differenceInDays < 15) ||
      (filters.toConfirm && item.appointment_start && !item.confirmation_sent_date)
    );
  });

  const paginatedAppointments = filteredAppointments.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <>
      {appointments.length ? (
        <>
          <div className='filters'>
            <div className='filters-container'>
              <label className='filters-container-item'>
                <input
                  type="checkbox"
                  checked={filters.toContact}
                  onChange={() => handleFilterChange('toContact')}
                />
                <span>À relancer</span>
              </label>
              <label className='filters-container-item'>
                <input
                  type="checkbox"
                  checked={filters.contactSoon}
                  onChange={() => handleFilterChange('contactSoon')}
                />
                <span>À relancer prochainement</span>
              </label>
              <label className='filters-container-item'>
                <input
                  type="checkbox"
                  checked={filters.toConfirm}
                  onChange={() => handleFilterChange('toConfirm')}
                />
                <span>À confirmer</span>
              </label>
            </div>
          </div>

          <table className="intervenants-list">
            <thead>
              <tr className="custom-cell-thead">
                <td className="custom-cell-label" colSpan={11}>
                  <strong>INTERVENANTS</strong>
                  {(filters.toConfirm || filters.contactSoon || filters.toContact) && <span style={{ marginLeft: '15px', fontSize: '0.9em' }}>
                    ({filteredAppointments.length} résultat{filteredAppointments.length > 1 ? 's' : ''})
                  </span>}
                </td>
              </tr>
            </thead>
            <tbody>
              <tr className="custom-cell-title">
                <td className="custom-cell-label" style={{ width: '55px' }}>Dossier</td>
                <td className="custom-cell-label">Entreprise</td>
                <td className="custom-cell-label">Lot</td>
                <td className="custom-cell-label" style={{ width: '130px' }}>Date d'intervention</td>
                <td className="custom-cell-label" style={{ width: '90px' }}>Contacté le</td>
                <td className="custom-cell-label" style={{ width: '90px' }}>Relancé le</td>
                <td className="custom-cell-label">Date du RDV</td>
                <td className="custom-cell-label" style={{ width: '90px' }}>Confirmé le</td>
                <td style={{ borderBlockEnd: '1px solid #C2C1C1' }} />
                <td className="custom-cell-label" style={{ borderBlockEnd: '1px solid #C2C1C1' }}>PPSPS</td>
                <td style={{ borderBlockEnd: '1px solid #C2C1C1' }} />
              </tr>
              {paginatedAppointments.map((item, i) => {
                const today = moment(new Date());
                const mailSentDate = moment(item.mail_sent_date);
                const differenceInDays = today.diff(mailSentDate, 'days');
                const backgroundColor = item.appointment_start ? '#d5ffd5' : differenceInDays >= 15 ? '#ffd5d5' : '';

                return (
                  <tr className="custom-cell" key={`appointment-${i}`} style={{ backgroundColor: backgroundColor }}>
                    <td className="custom-cell-label" style={{ textAlign: 'center' }}>{item.ndossier}</td>
                    <td className="custom-cell-label" style={{ textAlign: 'center', color: item.company ? '' : 'red' }}>{item.company === '' ? 'À définir' : item.company}</td>
                    <td className="custom-cell-label" style={{ textAlign: 'center' }}>{item.lot}</td>
                    <td className="custom-cell-label" style={{ textAlign: 'center', color: item.intervention_date ? '' : 'red' }}>{item.intervention_date ? moment(item.intervention_date).format('DD/MM/YYYY') : 'À définir'}</td>
                    <td className="custom-cell-label" style={{ textAlign: 'center', fontWeight: item.mail_sent_date ? 'bold' : '', color: 'green' }}>{item.mail_sent_date ? format(item.mail_sent_date, 'dd/MM/yyyy') : ''}</td>
                    <td className="custom-cell-label" style={{ textAlign: 'center', fontWeight: item.reminder_date ? 'bold' : '', color: 'green' }}>{item.reminder_date ? format(item.reminder_date, 'dd/MM/yyyy') : ''}</td>
                    <td className="custom-cell-label" style={{ textAlign: 'center', fontWeight: item.appointment_start ? 'bold' : '', color: item.appointment_start ? 'green' : 'red' }} >
                      {item.appointment_start ?
                        `${moment.utc(item.appointment_start).format('DD/MM/YYYY')} - ${moment.utc(item.appointment_start).format('HH:mm')} à ${moment.utc(item.appointment_end).format('HH:mm')}`
                        : item.mail_sent_date ? 'À confirmer' : ''}
                    </td>
                    <td className="custom-cell-label" style={{ textAlign: 'center', fontWeight: item.confirmation_sent_date ? 'bold' : '', color: item.confirmation_sent_date ? 'green' : 'red' }}>{item.confirmation_sent_date ? format(item.confirmation_sent_date, 'dd/MM/yyyy') : item.appointment_start ? 'À envoyer' : ''}</td>
                    <td className="custom-cell-label">
                      <IconButton
                        style={{ margin: '0 5px' }}
                        aria-label="send"
                        size="small"
                        className="send"
                        onClick={() => {
                          setIntervenant(item)
                          setIsConfirmationAppointment(true)
                          setSendEmail(true)
                        }}
                        disabled={!item.appointment_start || !item.appointment_end || !item.mail || item.confirmation_sent_date !== null}
                        title={item.confirmation_sent ? `Envoyé le ${format(item.confirmation_sent_date, 'dd/MM/yyyy')}` : "Envoyer confirmation RDV"}
                      >
                        <EventAvailableIcon style={{ color: item.confirmation_sent ? 'green' : "", fontSize: '28px' }} />
                      </IconButton>
                    </td>
                    <td className="custom-cell-label" style={{ textAlign: 'center', color: item.ppsps ? 'green' : 'red' }}>
                      <input type="checkbox" checked={true} readOnly style={{ width: '20px', height: '20px', accentColor: item.ppsps ? 'green' : 'red' }} />
                    </td>
                    <td className="custom-cell-label">
                      <IconButton
                        style={{ margin: '0 5px' }}
                        aria-label="send"
                        size="small"
                        className="send"
                        onClick={() => {
                          setIntervenant(item)
                          setIsConfirmationAppointment(false)
                          setSendEmail(true)
                        }}
                        disabled={!item.mail || item.appointment_start !== null || !item.mail_sent}
                        title="Envoyer un rappel"
                      >
                        <ContactMailIcon style={{ color: item.mail_sent ? 'green' : "" }} />
                      </IconButton>
                    </td>
                  </tr>
                )
              })}
              <tr className="custom-cell">
                <td className="custom-cell-label" colSpan={11}>
                  <div style={{ display: 'flex', justifyContent: 'space-evenly', alignItems: 'center' }}>
                    <button onClick={() => handleChangePage(null, currentPage - 1)} style={{ fontSize: '14px', padding: '5px 10px' }} disabled={currentPage === 1}>Précédent</button>
                    <select value={rowsPerPage} style={{ fontSize: '14px', padding: '5px 10px', textAlign: 'center' }} onChange={handleChangeRowsPerPage}>
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={20}>20</option>
                      <option value={50}>50</option>
                    </select>
                    <button onClick={() => handleChangePage(null, currentPage + 1)} style={{ fontSize: '14px', padding: '5px 18px' }} disabled={currentPage === Math.ceil(filteredAppointments.length / rowsPerPage)}>Suivant</button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      ) : null}
    </>
  );
};

export default ContactList;