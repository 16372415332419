import { Button } from '@material-ui/core';
import React from 'react';
import '../../../Styles/Devis/CreaDossier.scss';
import Header from '../../Header/Header';
import LoadingOverlay from "../../LoadingOverlay";
import { connect } from 'react-redux';
import { fetchDevis } from "../../../redux/actions/devisActions";
import { fetchContact } from '../../../redux/actions/contactActions';
import { Slider } from '@material-ui/core';
import ConfirmDoss from './Dialogs/ConfirmDoss';
import SuppDialog from './Dialogs/SuppDialog';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from '@material-ui/core/Typography';
import CompMo from './CompMo';
import { fetchDossier } from '../../../redux/actions/dossierActions';
import getEntrepriseFromName from "../../Utils";
import { fetchEntreprise } from "../../../redux/actions/entrepriseActions";

class CreaDossier extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      numDoss: "",
      op: "",
      addrop: "",
      villeop: "",
      cpop: "",
      catop: "1",
      coutop: "",
      domaine: "Public",
      duréeop: 0,
      company: "",
      mo: "",
      sexe: "",
      idmo: "",
      adress: "",
      city: "",
      cp: "",
      tel_port: "",
      mail: "",
      nomDoss: "DOSSIER DEVIS N° ",
      isLoading: false,
      disableButton: false,
      creaDialog: false,
      suppDialog: false,
      modifDialog: false,
      selectedDossier: null,
      index: 0,
      isCrea: false,
      openDialog: false,
      unauthorized: "",
    }
    this.handleChange = this.handleChange.bind(this);
    this.setOpenCrea = this.setOpenCrea.bind(this);
    this.setOpenSupp = this.setOpenSupp.bind(this)
    this.setOpenModif = this.setOpenModif.bind(this);
    this.setCompany = this.setCompany.bind(this);
    this.setMo = this.setMo.bind(this);
    this.handleChangeMoInfos = this.handleChangeMoInfos.bind(this);
  }

  /**
   * Initialise le nom du dossier avec le dernier numéro de dossier +1
   */
  getNumDoss() {
    fetch("/api/devis/getNumDoss")
      .then(res => res.json())
      .then(res => this.setState({ numDoss: Number(res[0].max_ndossier) + 1 }, () => {
        this.setState({ nomDoss: "DOSSIER DEVIS N° " + this.state.numDoss });
      }));
  }

  /**
   * Initialise les données (devis, contacts, etc.) dès le montage du composant
   */
  async componentDidMount() {
    if (this.props.deviscon.length === 0 || this.props.deviscon === undefined) {
      await this.props.dispatch(fetchDevis());
      await this.props.dispatch(fetchContact());
    }
    this.getNumDoss();
    this.props.dispatch(fetchEntreprise());
  }

  /**
   * Change le nom du dossier en fonction des données du dossier
   * @param {Object} event 
   */
  handleChange(event) {
    const regex = /[\\/*^?"&@#ç!§(°%$£;:€)={}\[\]]/g;
    const value = event.target.value;
    if (regex.test(value)) {
      this.setState({
        unauthorized: value.charAt(value.length - 1),
        openDialog: true
      });
      return;
    }
    if (
      getEntrepriseFromName(value, this.props.entreprise).id === undefined
    ) {
      this.handleChangeMoInfos(true, "", "", "", "", "", "homme");
      this.setState({ idmo: "" });
    }
    this.setState({ [event.target.name]: value }, () => {
      this.setState({
        nomDoss: "DOSSIER DEVIS N° " + this.state.numDoss + " " + this.state.op + " " + this.state.mo + " " + this.state.villeop + " " + this.state.company
      });
    });
  }

  handleChangeNumDoss = (event) => {
    const regex = /^\d+$/;
    let value = event.target.value;
    if (event.target.name === "numDoss") {
      if (value === "" || regex.test(value)) {
        this.setState((prevState) => ({
          numDoss: value,
          nomDoss: "DOSSIER DEVIS N° " + value + " " + prevState.op + " " + prevState.mo + " " + prevState.villeop + " " + prevState.company
        }));
      }
    }
  }

  /**
   * Permet à l'utilisateur de ne rentrer que des chiffres ou décimaux
   * @param {Object} event 
   */
  handleCoutChange(event) {
    if (/^[0-9][\.\d]*$/.test(event.target.value) || event.target.value === "") {
      this.setState({ [event.target.name]: event.target.value });
    }
  }

  /**
   * Permet de changer la durée via un Slider
   * @param {Object} event 
   */
  handleSliderChange(event) {
    const value = event.target.value === "" || isNaN(parseFloat(event.target.value)) ? 0 : parseFloat(event.target.value);
    this.setState({ duréeop: value });
  }

  /**
   * Envoie les données du dossier vers le Back
   */
  async handleClickDossier() {
    this.setState({ disableButton: true, isLoading: true });
    const json = {};
    Object.keys(this.state).forEach(key => {
      json[key] = this.state[key];
    });
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify(json)
    };
    let resp1 = await fetch("/api/entreprise/verifDossier", requestOptions)
      .catch((err) => console.log(err));
    if (resp1.ok) {
      let resp2 = await fetch("/api/contact/verifDossier", requestOptions)
        .catch((err) => console.log(err));
      if (resp2.ok) {
        await fetch("/api/devis/createDossier", requestOptions)
          .then(res => {
            if (res.status === 200) {
              this.setState({ numDoss: this.state.numDoss + 1 });
              this.setState({ op: "", addrop: "", villeop: "", cpop: "", coutop: "", duréeop: 0, domaine: "Public", catop: "1", adress: "", city: "", cp: "", mo: "", tel_port: "", mail: "", sexe: "homme" });
              this.setCompany("");
            }
          })
          .catch((err) => console.log(err));
      }
    }
    await this.getNumDoss();
    await this.props.dispatch(fetchDevis());
    await this.props.dispatch(fetchDossier());
    await this.props.dispatch(fetchContact());
    this.setState({ disableButton: false, isLoading: false });
  }

  /**
   * Met à jour l'état "company" et le nom du dossier
   * @param {string} value 
   */
  setCompany(value) {
    this.setState({ company: value }, () => {
      this.setState({
        nomDoss: "DOSSIER DEVIS N° " + this.state.numDoss + " " + this.state.op + " " + this.state.mo + " " + this.state.villeop + " " + this.state.company
      });
    });
  }

  /**
   * Met à jour l'état "mo"
   * @param {string} value 
   * @param {string} id 
   */
  setMo(value, id) {
    this.setState({ mo: value, idmo: id });
  }

  handleChangeMoInfos(event, adress, city, cp, tel_port, mail, sexe) {
    if (event.target) {
      this.setState({ [event.target.name]: event.target.value });
    } else {
      this.setState({ adress, city, cp, tel_port, mail, sexe });
    }
  }

  async getDossierDetails(index) {
    const ndossier = this.props.dossierTab[index].ndossier;
    try {
      const response = await fetch(`/api/devis/getDossierDetails/${ndossier}`);
      if (!response.ok) {
        throw new Error(`Erreur HTTP: ${response.status}`);
      }
      const data = await response.json();
      this.setState({ selectedDossier: data[0] });
    } catch (err) {
      console.error(err);
    }
  }


  /**
   * Ouvre ou ferme le dialogue de modification d'un dossier
   * @param {boolean} isOpen 
   * @param {number} index 
   */
  async setOpenModif(isOpen, index = null) {
    if (index !== null) await this.getDossierDetails(index);
    if (isOpen && index !== null) {
      await this.setState({ modifDialog: true });
    } else {
      await this.setState({ modifDialog: false, selectedDossier: null });
    }
  }

  handleStatutChange(event) {
    const value = parseInt(event.target.value);
    this.setState(prevState => ({
      selectedDossier: {
        ...prevState.selectedDossier,
        abandoned: value,
      },
    }));
  }

  handleCloseModif = () => {
    this.setOpenModif(false);
  };


  /**
   * Supprime un dossier de la liste
   *
   */
  async handleSuppDoss() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ numDoss: this.state.selectedDossier.ndossier })
    };
    await fetch("/api/devis/delete", requestOptions)
    await this.getNumDoss()
    await this.props.dispatch(fetchDevis())
    await this.props.dispatch(fetchDossier())
    await this.setOpenModif(false);
  }

  /**
   * Lors de la confirmation, on charge les infos du dossier dans le formulaire pour modification
   */
  handleConfirmModif = async () => {
    const { ndossier, abandoned } = this.state.selectedDossier;

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
      body: JSON.stringify({ ndossier, abandoned })
    };

    await fetch("/api/devis/modifDossier", requestOptions)
      .then(res => {
        if (res.status === 200) {
          this.props.dispatch(fetchDossier());
        }
      })
      .catch((err) => console.log(err));

    this.setOpenModif(false);
  };

  /**
   * Affiche ou cache le dialogue de création d'un dossier
   * @param {boolean} isOpen 
   */
  setOpenCrea(isOpen) {
    this.setState({ creaDialog: isOpen });
  }

  /**
   * Affiche le dialogue de suppréssion d'un dossier en fonction de l'index de celui-ci et le fait disparaître en fonction de "isOpen"
   * @param {boolean} isOpen 
   */
  setOpenSupp(isOpen) {
    this.setState({ suppDialog: isOpen })
  }

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const inputProps = {
      name: "mo",
      value: this.state.mo,
      onChange: this.handleChange
    };

    return (
      <div className="creaDossier">
        {this.state.isLoading && <LoadingOverlay />}
        <Header />
        <h1 className="title">Création de dossier</h1>
        <div className="cadreDoss">
          <div className="formDoss">
            <div className="opInfos">
              <div>
                <label>Numéro de dossier : </label>
                <input value={this.state.numDoss} name="numDoss" onChange={this.handleChangeNumDoss} />
              </div>
              <div>
                <label>Opération : </label>
                <input value={this.state.op} name="op" onChange={this.handleChange} />
              </div>
              <div>
                <label>Adresse opération : </label>
                <input value={this.state.addrop} name="addrop" onChange={this.handleChange} />
              </div>
              <div>
                <label>Ville : </label>
                <input value={this.state.villeop} name="villeop" onChange={this.handleChange} />
              </div>
              <div>
                <label>Code Postal : </label>
                <input value={this.state.cpop} name="cpop" onChange={this.handleChange} />
              </div>
              <div>
                <label>Coût : </label>
                <input value={this.state.coutop} name="coutop" onChange={this.handleCoutChange.bind(this)} />
                <label>(en millions €)</label>
              </div>
            </div>
            <div className="mouInfos">
              <div>
                <label>Domaine : </label>
                <select name="domaine" onChange={this.handleChange}>
                  <option value="public">Public</option>
                  <option value="privé">Privé</option>
                </select>
              </div>
              <div>
                <label>Catégorie : </label>
                <select name="catop" onChange={this.handleChange}>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                </select>
              </div>
              <div className='durée'>
                <label>Durée: </label>
                <div>
                  <Slider value={this.state.duréeop} step={0.5} min={0} max={48} onChange={(_, value) => this.setState({ duréeop: value })} />
                  <input value={this.state.duréeop} style={{ width: "30px" }} onChange={this.handleSliderChange.bind(this)} />
                  <label> Mois</label>
                </div>
              </div>
              <CompMo
                inputProps={inputProps}
                handleChange={this.handleChange}
                company={this.state.company}
                setCompany={this.setCompany}
                setMo={this.setMo}
                handleChangeMoInfos={this.handleChangeMoInfos}
                adress={this.state.adress}
                city={this.state.city}
                cp={this.state.cp}
                tel_port={this.state.tel_port}
                mail={this.state.mail}
                sexe={this.state.sexe}
              />
            </div>
          </div>
          <div>
            <label>{this.state.nomDoss}</label>
          </div>
          <Button
            variant="contained"
            color="primary"
            onClick={() => this.setOpenCrea(true)}
            disabled={this.state.disableButton}
            style={{ width: '150px', fontSize: '15px', height: '40px' }}
          >
            Créer
          </Button>
        </div>
        <div className="listeDoss">
          {this.props.dossierTab.map((doss, i) =>
            <div key={i}>
              <label>Dossier N° {doss.ndossier}</label>
              <Button
                variant="contained"
                onClick={() => this.setOpenModif(true, i)}
              >
                Modifier
              </Button>
            </div>
          )}
        </div>
        <Dialog open={this.state.openDialog} onClose={this.handleClose}>
          <DialogTitle>INFORMATION</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Le caractère que vous avez saisi '{this.state.unauthorized}' n'est pas autorisé.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              variant="contained"
              onClick={this.handleClose}
              style={{ color: "white", backgroundColor: "#FF7F50" }}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog open={this.state.modifDialog} onClose={this.handleCloseModif}>
          <DialogTitle>MODIFIER LE DOSSIER</DialogTitle>
          <DialogContent>
            <Typography component="span">
              {this.state.selectedDossier ? (
                <div>
                  <Typography><b>Numéro :</b> {this.state.selectedDossier.ndossier}</Typography>
                  <Typography><b>Opération :</b> {this.state.selectedDossier.operation}</Typography>
                  <Typography><b>Adresse :</b> {this.state.selectedDossier.adresse_op}, {this.state.selectedDossier.ville_op} - {this.state.selectedDossier.cp_op}</Typography>
                  <Typography><b>Statut :</b>
                    <select
                      style={{ marginLeft: '10px', border: 'none', padding: '7px', borderRadius: '6px', backgroundColor: 'gainsboro', color: this.state.selectedDossier.abandoned ? 'red' : 'green', fontWeight: 'bold' }}
                      value={this.state.selectedDossier.abandoned}
                      onChange={(event) => this.handleStatutChange(event)}
                    >
                      <option value={0} style={{ color: 'green', fontWeight: 'bold' }}>En cours</option>
                      <option value={1} style={{ color: 'red', fontWeight: 'bold' }}>Sans suite</option>
                    </select>
                  </Typography>
                </div>
              ) : "Aucune information disponible."}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => this.setOpenSupp(true)} style={{ color: "white", backgroundColor: "red" }}>
              Supprimer
            </Button>
            <Button variant="contained" onClick={this.handleConfirmModif} style={{ color: "white", backgroundColor: "#FF7F50" }}>
              Modifier
            </Button>
            <Button variant="contained" onClick={this.handleCloseModif} style={{ color: "white", backgroundColor: "#f5b039" }}>
              Annuler
            </Button>
          </DialogActions>
        </Dialog>

        <ConfirmDoss
          open={this.state.creaDialog}
          setOpen={this.setOpenCrea}
          onConfirm={this.handleClickDossier.bind(this)}
        />
        <SuppDialog
          open={this.state.suppDialog}
          setOpen={this.setOpenSupp}
          onConfirm={this.handleSuppDoss.bind(this)}
          index={this.state.selectedDossier?.ndossier}
        />
      </div>
    );
  }
}

const mapStateToProps = state => ({
  devisrea: state.devis.devisrea,
  deviscon: state.devis.deviscon,
  dossierValue: state.devis.dossierValue,
  dossierTab: state.devis.dossierTab,
  contact: state.contact.items,
  entreprise: state.entreprise.items,
});

export default connect(mapStateToProps)(CreaDossier);
