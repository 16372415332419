import React from "react";
import BaseTable, { Column } from "react-base-table";
import "react-base-table/styles.css";
import "../../../Styles/RJ/RisqueTable.scss";
import PopperLevels from "./PopperLevels";
import { Checkbox } from "@material-ui/core";
import { IconButton } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Add from "@material-ui/icons/Add";
import InformationDialog from "../../InformationDialog";

class RisqueTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      factors: [],
      index: "",
      openDialog: false
    };
  }

  componentDidMount() { 
    this.setState({ factors: [...this.props.facteurs] })
  }

  componentDidUpdate(prevProps) {
    if (this.props.facteurs !== prevProps.facteurs) {
      this.setState({ factors: [...this.props.facteurs] })
    }
  }

  checkType(type) {
    if (type === "fort") return "red";
    if (type === "pondéré") return "purple";
    if (type === "moyen") return "yellow";
    if (type === "faible") return "green";
    else return "gray";
  }

  filterFacteurs(listFacteurs, rowIndex) {
    const { facteurs } = this.props;
    return listFacteurs.filter((item) => {
      for (let i = 0; i < facteurs.length; i++) {
        if (
          item.name === facteurs[i].facteur &&
          item.name !== "" &&
          i !== rowIndex
        ) {
          return;
        }
      }
      return item;
    });
  }

  handleClickOpen = () => {
    this.setState({ openDialog: true });
  };

  handleClose = () => {
    this.setState({ openDialog: false });
  };

  render() {
    const { listFactors, nbFloor, valueArray } = this.props;
    const { factors } = this.state;

    let widthColumn = 0;
    switch (nbFloor) {
      case 0:
        widthColumn = 450;
        break;
      case 1:
        widthColumn = 650;
        break;
      case 2:
        widthColumn = 650;
        break;
      case 3:
        widthColumn = 800;
        break;
      case 4:
        widthColumn = 900;
        break;
      case 5:
        widthColumn = 1000;
        break;
      case 6:
        widthColumn = 1100;
        break;
      case 7:
        widthColumn = 1200;
        break;
    }

    let column = [
      {
        title: "FACTEUR DE RISQUE",
        key: "facteur",
        dataKey: "facteur",
        width: 200,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => {
          return (
            <div className="listFacteurs">
              <select
                name="facteur"
                style={{
                  height: "100%",
                  width: "100%",
                  pointerEvents: "visible",
                  textAlign: "center",
                  fontSize: "14px",
                }}
                value={rowData.facteur}
                onChange={(event) =>
                  this.props.handleFacteursChange(event, rowIndex)
                }
              >
                {this.filterFacteurs(listFactors, rowIndex).map(
                  (item, index) => {
                    return (
                      <option value={item.name} key={index}>
                        {item.name}
                      </option>
                    );
                  }
                )}
              </select>
            </div>
          );
        },
      },
      {
        title: "SOUS-SOL / RDC",
        key: "rdc",
        dataKey: "rdc",
        width: 200,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowIndex, rowData }) => {
          return (
            <div className="table-cell">
              <Checkbox
                name="answer"
                onChange={
                  valueArray[rowIndex].answer
                    ? (event) => {
                      rowData.facteur !== ""
                        ? this.props.handleLevelClick(event, rowIndex, "")
                        : this.handleClickOpen()
                    }
                    : (event) => {
                      rowData.facteur !== ""
                        ? this.props.showLevels(event, rowIndex)
                        : this.handleClickOpen()
                    }
                }
                style={{
                  color: this.checkType(valueArray[rowIndex].type),
                }}
                checked={valueArray[rowIndex].answer}
              />
            </div>
          );
        },
      },
      {
        width: 50,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowData, rowIndex }) => (
          <IconButton
            aria-label="delete"
            size="small"
            onClick={() => {
              this.props.handleClickOpenDel(rowIndex);
            }}
            style={{ backgroundColor: "transparent" }}
            className="delete"
          >
            <Delete />
          </IconButton>
        ),
      },
    ];

    for (let i = 1; i <= nbFloor; i++) {
      let newFloor = {
        title: "ÉTAGE " + i,
        key: "r+" + i,
        dataKey: "r+" + i,
        width: 200,
        align: Column.Alignment.CENTER,
        cellRenderer: ({ rowIndex, rowData }) => {
          return (
            <Checkbox
              name="answer"
              onChange={
                valueArray[rowIndex + 17 * i].answer
                  ? (event) =>
                    rowData.facteur !== ""
                      ? this.props.handleLevelClick(
                        event,
                        rowIndex + 17 * i,
                        ""
                      )
                      : this.handleClickOpen()
                  : (event) =>
                    rowData.facteur !== ""
                      ? this.props.showLevels(event, rowIndex + 17 * i)
                      : this.handleClickOpen()
              }
              style={{
                color: this.checkType(
                  valueArray[rowIndex + 17 * i].type
                ),
              }}
              checked={valueArray[rowIndex + 17 * i].answer}
            />
          );
        },
      };
      column.splice(i + 1, 0, newFloor);
    }

    return (
      <div className="risqueTable">
        <div className="risqueTableFloor">
          <BaseTable
            columns={column}
            width={widthColumn}
            height={(factors.length + 1) * 50}
            data={factors}
            rowKey="facteur"
          ></BaseTable>
        </div>
        {factors.length < 17 && (
          <IconButton
            aria-label="add"
            size="small"
            style={{ backgroundColor: "transparent" }}
            onClick={this.props.addFacteur}
          >
            <Add />
          </IconButton>
        )}
        <InformationDialog
          isOpen={this.state.openDialog}
          title={'INFORMATION'}
          information={'Veuillez sélectionner un facteur de risque'}
          handleClose={this.handleClose}
        />
        <PopperLevels
          open={this.props.open}
          anchorEl={this.props.anchorEl}
          hidLevels={this.props.hidLevels}
          levelsWrapper={this.props.levelsWrapper}
          handleLevelClick={this.props.handleLevelClick}
          index={this.props.index}
        />
      </div>
    );
  }
}

export default RisqueTable;
