import React from 'react';
import Button from '@material-ui/core/Button';
import { appointmentConst } from './Const';
import moment from "moment";

const AppointmentForm = ({ handleAddAppointment, handleUpdateAppointment, isAdd, newAppointment, setNewAppointment, lotList, setIsAdd }) => {

  return (
    <table className="appointment-form">
      <thead>
        <tr className="custom-cell-title">
          <td className="custom-cell-label" colSpan={2}><strong>{isAdd ? 'Ajouter un intervenant' : 'Modifier l\'intervenant'}</strong></td>
        </tr>
      </thead>
      <tbody>
        <tr className="custom-cell">
          <td>Type</td>
          <td><input type="text" name="type" value={newAppointment.type} onChange={(e) => setNewAppointment(prevState => ({ ...prevState, type: e.target.value }))} placeholder='VIC...' /></td>
        </tr>
        <tr className="custom-cell">
          <td>Entreprise</td> 
          <td><input type="text" name="company" value={newAppointment.company} onChange={(e) => setNewAppointment(prevState => ({ ...prevState, company: e.target.value }))} /></td>
        </tr>
        <tr className="custom-cell">
          <td>Lot</td>
          <td>
            <select
              style={{
                width: "40%",
                pointerEvents: "visible",
                textAlign: "center",
                fontSize: "14px",
                border: "none",
                padding: "7px",
              }}
              value={newAppointment.lot}
              onChange={(e) => setNewAppointment(prevState => ({ ...prevState, lot: e.target.value }))}
            >
              <option key={'-0'} value={''}></option>
              {lotList.map((item, index) => (
                <option key={index} value={item}>{item}</option>
              ))}
            </select>
          </td>
        </tr>
        <tr className="custom-cell">
          <td>Contact</td>
          <td><input type="text" name="representative" value={newAppointment.representative} onChange={(e) => setNewAppointment(prevState => ({ ...prevState, representative: e.target.value }))} placeholder='"Jean Dupont"'/></td>
        </tr>
        <tr className="custom-cell">
          <td>Téléphone</td>
          <td><input type="text" name="tel" value={newAppointment.tel} onChange={(e) => setNewAppointment(prevState => ({ ...prevState, tel: e.target.value }))} /></td>
        </tr>
        <tr className="custom-cell">
          <td>Mail</td>
          <td><input type="text" name="mail" value={newAppointment.mail} onChange={(e) => setNewAppointment(prevState => ({ ...prevState, mail: e.target.value }))} /></td>
        </tr>
        <tr className="custom-cell">
          <td>Date d'intervention</td>
          <td><input type="date" name="intervention_date" value={newAppointment.intervention_date} onChange={(e) => setNewAppointment(prevState => ({ ...prevState, intervention_date: e.target.value }))} /></td>
        </tr>
        <tr className="custom-cell">
          <td>Date du RDV</td>
          <td>
            <input
              type="date"
              name="appointment_date"
              value={newAppointment.appointment_start ? moment.utc(newAppointment.appointment_start).format('YYYY-MM-DD') : ''}
              onChange={(e) => {
                const newDate = e.target.value;
                setNewAppointment(prevState => {
                  if (newDate === '') {
                    return {
                      ...prevState,
                      appointment_start: null,
                      appointment_end: null
                    };
                  } else {
                    return {
                      ...prevState,
                      appointment_start: moment.utc(`${newDate} ${moment.utc(prevState.appointment_start || moment()).format('HH:mm:ss')}`, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
                      appointment_end: moment.utc(`${newDate} ${moment.utc(prevState.appointment_end || moment()).format('HH:mm:ss')}`, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss')
                    };
                  }
                });
              }}
            />
            de
            <input
              type="time"
              name="appointment_start_time"
              value={moment.utc(newAppointment.appointment_start).format('HH:mm') || ''}
              onChange={(e) => {
                const [hours, minutes] = e.target.value.split(':');
                setNewAppointment(prevState => ({
                  ...prevState,
                  appointment_start: moment(prevState.appointment_start)
                    .set({ hour: hours, minute: minutes })
                    .format('YYYY-MM-DD HH:mm:ss')
                }));
              }}
            />
            à
            <input
              type="time"
              name="appointment_end_time"
              value={moment.utc(newAppointment.appointment_end).format('HH:mm') || ''}
              onChange={(e) => {
                const [hours, minutes] = e.target.value.split(':');
                setNewAppointment(prevState => ({
                  ...prevState,
                  appointment_end: moment(prevState.appointment_start)
                    .set({ hour: hours, minute: minutes })
                    .format('YYYY-MM-DD HH:mm:ss')
                }));
              }}
            />
          </td>
        </tr>
        {newAppointment.type.trim().toLowerCase().includes('vic') && <tr className="custom-cell">
          <td>PPSPS</td>
          <td>
            <input
              type="checkbox"
              name="ppsps"
              checked={newAppointment.ppsps}
              onChange={(e) => setNewAppointment(prevState => ({ ...prevState, ppsps: e.target.checked }))}
            />
          </td>
        </tr>}
        <tr className="custom-cell">
          <td className="buttons-container" colSpan={2} style={{ display: 'table-cell' }}>
            <Button variant="contained" color="primary" type="submit" onClick={() => isAdd ? handleAddAppointment() : handleUpdateAppointment()}>{isAdd ? 'Ajouter' : 'Modifier'}</Button>
            <Button variant="contained" color="primary" type="submit" onClick={() => {
              setNewAppointment(appointmentConst)
              setIsAdd(true)
            }}>Annuler</Button>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AppointmentForm;