import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import Header from "../../Header/Header";
import FolderMenu from "../FolderMenu";
import GenPdf from "../../GenPdf";
import pdf_logo from './pdf_logo.svg';
import { format } from 'date-fns';
import { Button } from "@material-ui/core";
import LoadingOverlay from "../../LoadingOverlay";
import { normalizeCity, capitalizeFirstLetter } from '../../Dashboard/Utils';
import "../../../Styles/Contrat/ContratValid.scss";

const ContratValid = () => {
  const dossierValue = useSelector((state) => state.devis.dossierValue);

  const fileInputRef = useRef(null);

  const [contractExists, setContractExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [adress, setAdress] = useState('');
  const [operation, setOperation] = useState('');
  const [signedContract, setSignedContract] = useState('');
  const [isSigned, setIsSigned] = useState(false);
  const [signedDate, setSignedDate] = useState("");
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const loadData = async () => {
    if (!dossierValue) {
      initialiseValues();
      return;
    }

    setIsLoading(true);
    setIsOpen(false);

    try {
      const response = await fetch(`/api/contrat/getSignedSituation/${dossierValue}`);

      if (!response.ok) {
        setContractExists(false);
        initialiseValues();
      } else {
        const data = await response.json();
        if (data.validation === null) {
          setContractExists(false);
        } else {
          setContractExists(true);
        }
        const dataAdress = `${data.adresse_op}, ${data.cp_op} - ${normalizeCity(data.ville_op)}`;

        setAdress(dataAdress);
        setOperation(capitalizeFirstLetter(data.operation));
        setSignedContract(data.signed_contract)
        setIsSigned(data.validation);
        setSignedDate(data.signing_date ? format(data.signing_date, 'yyyy-MM-dd') : '');
        setFile(null);

        if (fileInputRef.current) {
          fileInputRef.current.value = "";
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [dossierValue]);

  const initialiseValues = () => {
    setAdress('');
    setOperation('');
    setIsSigned(false);
    setSignedDate('');
    setFile(null);
    setIsOpen(false);

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }

  const handleChangeSigned = (event) => {
    const { name, checked, value, files } = event.target;
    if (name === "signed") {
      setIsSigned(checked);
      if (!checked) {
        setSignedDate('');
      }
    } else if (name === "signed-file") {
      setFile(files[0] || null);
      if (files[0]) {
        setIsSigned(true);
      }
    } else {
      setSignedDate(value);
      if (value) {
        setIsSigned(true);
      }
    }
  }

  const handleSaveData = async () => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append('ndossier', dossierValue);
    formData.append('isSigned', isSigned ? 1 : 0);
    formData.append('signedDate', signedDate);

    if (file) formData.append('contractFile', file);

    try {
      const response = await fetch('/api/contrat/updateContract', {
        enctype: "multipart/form-data",
        method: 'POST',
        body: formData
      });
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      loadData();
    }
  }

  const handleShowPdf = async () => {
    setIsLoading(true);
    setFile(null);

    try {
      const response = await fetch(`/api/contrat/getSignedContrat/${dossierValue}`);
      const blob = await response.blob();
      setFile(blob);
      setIsOpen(true);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="contract-valid">
      {isLoading && <LoadingOverlay />}
      <Header />
      <h1 className="title">Validation du contrat</h1>
      <FolderMenu />
      <div className="container">
        <table className="custom-table">
          <thead className="custom-cell-head">
            <tr>
              <th colSpan="2">CONTRAT SIGNÉ</th>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td>Dossier</td>
              <td>
                <span> {dossierValue} </span>
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Adresse</td>
              <td>
                <span> {adress || ''} </span>
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Opération</td>
              <td>
                <span> {operation || ''} </span>
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Signé</td>
              <td>
                <input
                  type="checkbox"
                  name="signed"
                  checked={isSigned || false}
                  onChange={(event) => handleChangeSigned(event)}
                  style={{ accentColor: isSigned ? 'green' : '' }}
                  disabled={!contractExists}
                />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Date de signature</td>
              <td>
                <input
                  type="date"
                  name="signed-date"
                  value={signedDate || ''}
                  onChange={(event) => handleChangeSigned(event)}
                  disabled={!contractExists}
                />
              </td>
            </tr>
            <tr className="custom-cell">
              <td>Contrat signé</td>
              <td>
                <input
                  type="file"
                  name="signed-file"
                  multiple
                  accept=".pdf"
                  onChange={(event) => handleChangeSigned(event)}
                  ref={fileInputRef}
                  disabled={!contractExists}
                />
              </td>
            </tr>
          </tbody>
        </table>
        {signedContract && (
          <div className="img-container">
            <img
              src={pdf_logo}
              alt="pdf-shadow"
              className="img-shadow"
            />

            <img
              src={pdf_logo}
              alt="pdf-img"
              className="img-main"
              onClick={() => handleShowPdf()}
            />

            <p>Contrat signé</p>
          </div>
        )}

        <div className="buttons-container">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSaveData()}
            disabled={!contractExists}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleShowPdf()}
            disabled={!signedContract}
          >
            Afficher le PDF
          </Button>
        </div>
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default ContratValid;