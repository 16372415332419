import React, { Fragment, useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import Header from "../../../Header/Header";
import VICSMenu from "../../VICSMenu";
import DocMenu from "../../../DocMenu";
import GenPdf from "../../../GenPdf";
import history from "../../../Routes/history";
import { changeTitleName, changePdfFileName } from "../../../../redux/actions/emailAction";
import { changeVicValue, fetchCompaniesByDossierLot } from "../../../../redux/actions/vicActions";
import { newEntrepriseValue } from "../../../../redux/actions/entrepriseActions";
import { getQuestionsPage, updateQuestionsPage, getVicValues, genVicPDF, getVicPDF } from "../../Utils"
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import CircularProgress from "@material-ui/core/CircularProgress";
import "../../../../Styles/VIC/Page-4.scss";
 
const Page4 = ({ dispatch, vicValue, dossierValue, lotsValue, companies, entrepriseValue }) => {
  const [disableButton, setDisableButton] = useState(false);
  const [questionsLot, setQuestionsLot] = useState([]);
  const [file, setFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const prevDossierValue = useRef(dossierValue);
  const prevLotsValue = useRef(lotsValue);
  const prevCompanies = useRef(companies);
  const prevEntrepriseValue = useRef(entrepriseValue);
  const isMounted = useRef(true);

  useEffect(async () => {
    setDisableButton(true);
    const fetchQuestionsData = async () => {
      dispatch(changePdfFileName(""))
      const data = await getQuestionsPage(4, vicValue)
      if (isMounted.current) {
        setQuestionsLot(data !== null ? data : []);
      }
    };
    fetchQuestionsData();
    setDisableButton(false);
    return () => {
      isMounted.current = false;
    };
  }, [vicValue]);

  useEffect(async () => {
    setDisableButton(true);
    if (prevDossierValue.current !== dossierValue || prevLotsValue.current !== lotsValue) {
      await dispatch(fetchCompaniesByDossierLot(dossierValue, lotsValue));

      prevDossierValue.current = dossierValue;
      prevLotsValue.current = lotsValue;
    }
    setDisableButton(false);
  }, [dossierValue, lotsValue])

  useEffect(async () => {
    setDisableButton(true);
    if (prevCompanies.current !== companies) {
      if (companies.length !== 0) {
        await dispatch(newEntrepriseValue(companies[0]))
        const result = await getVicValues(dossierValue, lotsValue, companies[0]);
        await dispatch(changeVicValue(result.id))
      } else {
        await dispatch(changeVicValue(""))
      };

      prevCompanies.current = companies;
    }

    if (prevEntrepriseValue.current !== entrepriseValue) {
      const result = await getVicValues(dossierValue, lotsValue, entrepriseValue);

      await dispatch(changeVicValue(result.id))

      prevEntrepriseValue.current = entrepriseValue
    }
    setDisableButton(false);
  }, [companies, entrepriseValue])

  const handleValidClick = async () => {
    setDisableButton(true);
    await updateQuestionsPage(4, vicValue, questionsLot);
    setDisableButton(false);
  }

  const handleChange = (event, indexQstCat, indexQst) => {
    const { name, value, checked } = event.target;
    let temp_state = [...questionsLot];
    let temp_element = { ...temp_state[indexQstCat] };
    temp_element.questions[indexQst][name] = name === "answer" ? value === "1" ? true : false : checked ? true : false;
    temp_state[indexQstCat] = temp_element;
    setQuestionsLot(temp_state);
  };

  const handleGenClick = async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genVicPDF(vicValue);
    if (blob) {
      setFile(blob);
      setIsOpen(true);
    }
    setDisableButton(false);
  }

  const handleShowPdf = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');
    
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);

      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetPdfFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }

  const handleGetWordFile = async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getVicPDF(vicValue, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
    }
    setDisableButton(false);
  }

  const prevPage = () => {
    history.push("/vic/page-3");
  };

  const nextPage = () => {
    history.push("/vic/page-5");
  };

  const handleChangeTitleName = () => {
    dispatch(changeTitleName(`VIC`));
  };

  return (
    <div className="page4">
      <Header />
      <DocMenu
      file={file}
      getSourceFile={handleGetWordFile}
      getPDF={handleGetPdfFile}
      handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Visite d'Inspection Commune (Page 4)</h1>
      <VICSMenu />
      <div className="data-page4">
        <table className="questions-table">
          <tbody>
            {questionsLot.map((questionCategory, indexQstCat) => (
              <Fragment key={indexQstCat}>
                <tr>
                  <td colSpan="2" className="double-border">{questionCategory.cat_question}</td>
                </tr>
                {questionCategory.questions.map((question, indexQst) => (
                  <tr key={question.question}>
                    <td className="col-1">{question.question}</td>
                    <td>
                      <span style={{ marginLeft: "10px" }}>
                        <label>Oui</label>
                        <input
                          type="checkbox"
                          name="answer"
                          value={1}
                          onChange={(event) => handleChange(event, indexQstCat, indexQst)}
                          checked={question.answer}
                        />
                        <label>Non</label>
                        <input
                          type="checkbox"
                          name="answer"
                          value={0}
                          onChange={(event) => handleChange(event, indexQstCat, indexQst)}
                          checked={!question.answer ? true : false}
                        />
                        <label>Observation</label>
                        <input
                          type="checkbox"
                          name="observation"
                          onChange={(event) => handleChange(event, indexQstCat, indexQst)}
                          checked={question.observation}
                        />
                      </span>
                    </td>
                  </tr>
                ))}
              </Fragment>
            ))
            }
          </tbody>
        </table>
        <div className="buttons-container">
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleValidClick()}
            disabled={vicValue === "" || questionsLot.length === 0 || disableButton}
          >
            Enregistrer
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleGenClick()}
            disabled={vicValue === "" || questionsLot.length === 0 || disableButton}
          >
            Générer le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleShowPdf()}
            disabled={vicValue === "" || questionsLot.length === 0 || disableButton}
          >
            Afficher le PDF
          </Button>
          <Button
            color="primary"
            variant="contained"
            startIcon={<ArrowLeft />}
            onClick={prevPage}
            disabled={disableButton}
          >
            Page précedente
          </Button>
          <Button
            color="primary"
            variant="contained"
            endIcon={<ArrowRight />}
            onClick={nextPage}
            disabled={disableButton}
          >
            Page suivante
          </Button>
          {disableButton && <CircularProgress className="spinner" color="inherit" />}
        </div>
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
        pageNumber={4}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  dossierValue: state.devis.dossierValue,
  vicValue: state.vic.vicValue,
  lotsValue: state.vic.lotsValue,
  companies: state.vic.companies,
  entrepriseValue: state.entreprise.entrepriseValue,
});

export default connect(mapStateToProps)(Page4);
