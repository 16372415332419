import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import CircularProgress from "@material-ui/core/CircularProgress";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getProjectPresentationData, genDiuoPDF, getDiuoFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossDIUO from "../../NumDossDIUO";
import GenPdf from "../../GenPdf";
import LoadingOverlay from "../../LoadingOverlay";
import { siteParticipantsConst, preventionOrganizationsCont } from './Const';
import "../../../Styles/DIUO/Page3.scss";

const DiuoPage3 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.diuo.index);
  const diuoValue = useSelector((state) => state.diuo.diuoValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);
  const [siteParticipants, setSiteParticipants] = useState(siteParticipantsConst);
  const [preventionOrganizations, setPreventionOrganizations] = useState(preventionOrganizationsCont)

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const loadData = async () => {
    setIsLoading(true);
    const response = await getProjectPresentationData(diuoValue);
    const loadedSiteParticipants = response.projectPresentationData.site_participants ? JSON.parse(response.projectPresentationData.site_participants) : siteParticipantsConst;
    const loadedPreventionOrganizations = response.projectPresentationData.prevention_organizations ? JSON.parse(response.projectPresentationData.prevention_organizations) : preventionOrganizationsCont;

    setSiteParticipants(loadedSiteParticipants);
    setPreventionOrganizations(loadedPreventionOrganizations);
    setIsLoading(false);
  }

  useEffect(() => {
    if (!dossierValue) return
    if (!diuoValue) {
      setSiteParticipants(siteParticipantsConst);
      setPreventionOrganizations(preventionOrganizationsCont);
    } else loadData()
  }, [diuoValue]);

  const handleUpdateParticipant = (index, event) => {
    const { name, value } = event.target;
    setSiteParticipants((prevState) =>
      prevState.map((participant, i) =>
        i === index
          ? { ...participant, [name]: value }
          : participant
      )
    );
  };

  const handleAddSiteParticipants = () => {
    setSiteParticipants(prevState => [...prevState, {
      title: "",
      adress: '',
      representative: '',
      contact: ''
    }])
  }

  const handleDeleteSiteParticipants = (i) => {
    const tempSiteParticipants = siteParticipants.filter((item, index) => index !== i);
    setSiteParticipants(tempSiteParticipants);
  }

  const handleUpdatePreventionOrganizationsData = (key) => (event) => {
    const { name, value } = event.target;
    setPreventionOrganizations((prevState) => ({
      ...prevState,
      [key]: {
        ...prevState[key],
        [name]: value
      }
    }));
  };

  const handleSaveData = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        diuoValue,
        siteParticipants,
        preventionOrganizations
      }),
    };
    try {
      const response = await fetch("/api/diuo/saveProjectPresentation", requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genDiuoPDF(diuoValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getDiuoFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [diuoValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`DIUO`));
  }, [diuoValue]);

  const prevPage = () => {
    history.push("/diuo/page2");
  }

  const nextPage = () => {
    history.push("/diuo/page4");
  }

  return (
    <div className="diuo-page3">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">DIUO (Intervenants & Organismes de preventions)</h1>
      <NumDossDIUO />
      <div className="container">
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={5}><strong>Intervenants sur le chantier</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell-title">
              <td className="custom-cell-label"><strong>Intervenants</strong></td>
              <td className="custom-cell-label"><strong>Adresse</strong></td>
              <td className="custom-cell-label"><strong>Représentant</strong></td>
              <td className="custom-cell-label"><strong>Contact</strong></td>
              <td></td>
            </tr>
            {siteParticipants.map((item, i) => (
              <tr className="custom-cell" key={i}>
                <td>{i > (siteParticipants[2].title === 'Bureau de contrôle' ? 3 : 4) ? <textarea name="title" value={item.title} onChange={(event) => handleUpdateParticipant(i, event)} placeholder="&quot;Intervenant&quot;" /> : item.title}</td>
                <td><textarea name="adress" value={item.adress} onChange={(event) => handleUpdateParticipant(i, event)} placeholder="&quot;123 Rue de la République&quot;&#10;&quot;75001 - Paris&quot;" /></td>
                <td><textarea name="representative" value={item.representative} onChange={(event) => handleUpdateParticipant(i, event)} placeholder="&quot;Jean Dupont&quot;" /></td>
                <td><textarea name="contact" value={item.contact} onChange={(event) => handleUpdateParticipant(i, event)} placeholder="&quot;01 23 45 67 89&quot;&#10;&quot;jean.dupont@exemple.fr&quot;" /></td>
                {i > (siteParticipants[2].title === 'Bureau de contrôle' ? 3 : 4) ? <td style={{ width: '50px' }}>
                  <IconButton
                    style={{ marginLeft: '5px' }}
                    aria-label="delete"
                    size="small"
                    className="delete"
                    onClick={() => handleDeleteSiteParticipants(i)}
                  >
                    <Delete />
                  </IconButton>
                </td> : <td style={{ width: '50px' }}></td>}
              </tr>
            ))}
            <tr className="add-technical">
              <td>
                <IconButton
                  aria-label="add"
                  style={{ backgroundColor: "transparent" }}
                  onClick={handleAddSiteParticipants}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={4}><strong>Organisme de prévention institutionnels</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell-title">
              <td className="custom-cell-label"><strong>Intervenants</strong></td>
              <td className="custom-cell-label"><strong>Adresse</strong></td>
              <td className="custom-cell-label"><strong>Représentant</strong></td>
              <td className="custom-cell-label"><strong>Contact</strong></td>
            </tr>
            <tr className="custom-cell">
              <td>Inspection du travail</td>
              <td><textarea name="adress" value={preventionOrganizations.laborInspection.adress} onChange={handleUpdatePreventionOrganizationsData('laborInspection')} placeholder="&quot;Organisme&quot;&#10;&quot;123 Rue de la République&quot;&#10;&quot;75001 - Paris&quot;" /></td>
              <td><textarea name="representative" value={preventionOrganizations.laborInspection.representative} onChange={handleUpdatePreventionOrganizationsData('laborInspection')} placeholder="&quot;Jean Dupont&quot;" /></td>
              <td><textarea name="contact" value={preventionOrganizations.laborInspection.contact} onChange={handleUpdatePreventionOrganizationsData('laborInspection')} placeholder="&quot;01 23 45 67 89&quot;&#10;&quot;jean.dupont@exemple.fr&quot;" /></td>
            </tr>
            <tr className="custom-cell">
              <td>Caisse Régionale d'Assurance Maladie</td>
              <td><textarea name="adress" value={preventionOrganizations.CRAM.adress} onChange={handleUpdatePreventionOrganizationsData('CRAM')} placeholder="&quot;Organisme&quot;&#10;&quot;123 Rue de la République&quot;&#10;&quot;75001 - Paris&quot;" /></td>
              <td><textarea name="representative" value={preventionOrganizations.CRAM.representative} onChange={handleUpdatePreventionOrganizationsData('CRAM')} placeholder="&quot;Jean Dupont&quot;" /></td>
              <td><textarea name="contact" value={preventionOrganizations.CRAM.contact} onChange={handleUpdatePreventionOrganizationsData('CRAM')} placeholder="&quot;01 23 45 67 89&quot;&#10;&quot;jean.dupont@exemple.fr&quot;" /></td>
            </tr>
            <tr className="custom-cell">
              <td>O P P B T P</td>
              <td><textarea name="adress" value={preventionOrganizations.OPPBTP.adress} onChange={handleUpdatePreventionOrganizationsData('OPPBTP')} placeholder="&quot;Organisme&quot;&#10;&quot;123 Rue de la République&quot;&#10;&quot;75001 - Paris&quot;" /></td>
              <td><textarea name="representative" value={preventionOrganizations.OPPBTP.representative} onChange={handleUpdatePreventionOrganizationsData('OPPBTP')} placeholder="&quot;Jean Dupont&quot;" /></td>
              <td><textarea name="contact" value={preventionOrganizations.OPPBTP.contact} onChange={handleUpdatePreventionOrganizationsData('OPPBTP')} placeholder="&quot;01 23 45 67 89&quot;&#10;&quot;jean.dupont@exemple.fr&quot;" /></td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !diuoValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !diuoValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !diuoValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !diuoValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !diuoValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default DiuoPage3;