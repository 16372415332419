import React, { useMemo, useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { newDossierValue } from "../../../redux/actions/devisActions";
import { changeAmendmentValue, fetchEcheancesByNdossier, fetchEcheancesByAmendment, fetchEcheances } from "../../../redux/actions/echeancesActions";

const NumDoss = () => {
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const dossier = useSelector((state) => state.dossier.items);
  const echeances = useSelector((state) => state.echeances.items);
  const amendment = useSelector((state) => state.echeances.amendment);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!echeances?.length) {
      dispatch(fetchEcheances());
      return
    }

    if(dossierValue !== 0 && echeances.length !== 0 && amendment) {
      dispatch(fetchEcheancesByNdossier(dossierValue))
      dispatch(fetchEcheancesByAmendment(dossierValue, amendment))
    };
  }, [dispatch, echeances, dossierValue, amendment]);

  const handleDossierChange = useCallback((event) => {
    const newValue = +event.target.value;
    dispatch(newDossierValue(newValue));
    dispatch(changeAmendmentValue(1));
    dispatch(fetchEcheancesByAmendment(newValue, 1));
  }, [dispatch]);

  const handleAmendmentChange = useCallback((event) => {
    const newValue = +event.target.value;
    dispatch(changeAmendmentValue(newValue));
    dispatch(fetchEcheancesByAmendment(dossierValue, newValue));
  }, [dispatch, dossierValue]);

  const sortedEcheances = useMemo(() => {
    return [...echeances]
      .filter((item) => item.ndossier === dossierValue)
      .sort((a, b) => a.amendment - b.amendment)
      .map(({ id, amendment }) => ({ id, amendment }));
  }, [echeances, dossierValue]);

  return (
    <div className="ndossier">
      <label htmlFor="lang">N° Dossier : </label>
      <select
        id="lang"
        onChange={handleDossierChange}
        value={dossierValue}
        style={{ marginRight: "5px" }}
      >
        {dossier.map(({ ndossier }) => (
          <option key={ndossier} value={ndossier}>
            {ndossier}
          </option>
        ))}
      </select>

      <select
        id="langAmendment"
        onChange={handleAmendmentChange}
        value={amendment}
      >
        {sortedEcheances.map(({ id, amendment }) => (
          <option key={id} value={amendment}>
            {`Avenant N° ${amendment}`}
          </option>
        ))}
      </select>
    </div>
  );
};

export default NumDoss;