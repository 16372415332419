import React, { useEffect, useState, useCallback } from "react";
import { unstable_batchedUpdates } from 'react-dom';
import { useDispatch, useSelector } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import { IconButton } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import { changeTitleName, changePdfFileName } from "../../../redux/actions/emailAction";
import { getInterferenceOccupantsData, genPgcPDF, getPgcFile } from "../Utils";
import { Button } from "@material-ui/core";
import ArrowRight from "@material-ui/icons/ArrowRight";
import ArrowLeft from "@material-ui/icons/ArrowLeft";
import history from "../../Routes/history";
import Header from "../../Header/Header";
import DocMenu from "../../DocMenu";
import NumDossPGC from "../../NumDossPGC";
import GenPdf from "../../GenPdf";
import { collectiveProtectionsConst } from "./Const"
import LoadingOverlay from "../../LoadingOverlay";
import "../../../Styles/PGC/Page5.scss";

const initialCollectiveProtections = {
  nature: "",
  protection: "",
  completed: "",
  maintained: "",
  deadline: ""
};

const PgcPage5 = () => {
  const dispatch = useDispatch();
  const dossierValue = useSelector((state) => state.devis.dossierValue);
  const index = useSelector((state) => state.pgc.index);
  const pgcValue = useSelector((state) => state.pgc.pgcValue);

  const [isLoading, setIsLoading] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const [interferenceWithOccupants, setInterferenceWithOccupants] = useState({ office: '', room: '' })
  const [temporaryCollectiveProtections, setTemporaryCollectiveProtections] = useState(false);
  const [vigipirate, setVigipirate] = useState(false);
  const [collectiveProtections, setCollectiveProtections] = useState([initialCollectiveProtections]);

  const [customInputs, setCustomInputs] = useState([false]);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(null);

  const isCustomProtection = (protection) => {
    return protection.nature !== "" && !collectiveProtectionsConst.some(item => item.nature === protection.nature);
  };

  const loadData = async () => {
    if (!pgcValue) return resetData();
    setIsLoading(true);
    const responseInterferenceOccupantsData = await getInterferenceOccupantsData(pgcValue);

    if (responseInterferenceOccupantsData) {
      const interferenceWithOccupantsLoaded = responseInterferenceOccupantsData.interference_with_occupants ? JSON.parse(responseInterferenceOccupantsData.interference_with_occupants) : { office: '', room: '' };
      const temporaryCollectiveProtectionsLoaded = responseInterferenceOccupantsData.temporary_collective_protections;
      const vigipirateLoaded = responseInterferenceOccupantsData.vigipirate;
      const collectiveProtectionsLoaded = responseInterferenceOccupantsData.collective_protections ? JSON.parse(responseInterferenceOccupantsData.collective_protections) : [initialCollectiveProtections];

      unstable_batchedUpdates(() => {
        setInterferenceWithOccupants(interferenceWithOccupantsLoaded);
        setTemporaryCollectiveProtections(temporaryCollectiveProtectionsLoaded);
        setVigipirate(vigipirateLoaded);
        setCollectiveProtections(collectiveProtectionsLoaded);
        setCustomInputs(collectiveProtectionsLoaded.map(protection => isCustomProtection(protection)));
        setIsLoading(false);
      });
    } else {
      unstable_batchedUpdates(() => {
        resetData();
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!dossierValue) return
    loadData()

  }, [pgcValue]);

  const resetData = async () => {
    setInterferenceWithOccupants({ office: '', room: '' });
    setTemporaryCollectiveProtections(false);
    setVigipirate(false);
    setCollectiveProtections([initialCollectiveProtections]);
    setCustomInputs([false]);
  };

  const handleAddNatureWork = () => {
    setCollectiveProtections(prevState => [...prevState, initialCollectiveProtections]);
    setCustomInputs(prevState => [...prevState, false]);
  };

  const handleDeleteNatureWork = (index) => {
    if (collectiveProtections.length === 1) {
      setCollectiveProtections([initialCollectiveProtections]);
      setCustomInputs([false]);
    } else {
      setCollectiveProtections(prevState => prevState.filter((_, i) => i !== index));
      setCustomInputs(prevState => {
        const newCustomInputs = [...prevState];
        newCustomInputs.splice(index, 1);
        return newCustomInputs;
      });
    }
  };

  const handleUpdateCollectiveProtections = (event, index) => {
    const { value } = event.target;
    const list = [...collectiveProtections];

    if (value === "") {
      list[index] = initialCollectiveProtections;
      setCustomInputs(prevState => {
        const newCustomInputs = [...prevState];
        newCustomInputs[index] = false;
        return newCustomInputs;
      });
    } else if (value === "Autre...") {
      list[index] = {
        nature: "",
        protection: "",
        completed: "",
        maintained: "",
        deadline: ""
      };
      setCustomInputs(prevState => {
        const newCustomInputs = [...prevState];
        newCustomInputs[index] = true;
        return newCustomInputs;
      });
    } else {
      const selectedItem = collectiveProtectionsConst.find(item => item.nature === value);
      list[index] = { ...selectedItem };
      setCustomInputs(prevState => {
        const newCustomInputs = [...prevState];
        newCustomInputs[index] = false;
        return newCustomInputs;
      });
    }
    setCollectiveProtections(list);
  };

  const handleChangeCollectiveProtectionsValues = (event, index) => {
    const { name, value } = event.target;
    setCollectiveProtections(prevState => {
      const element = [...prevState];
      element[index] = {
        ...element[index],
        [name]: value
      };
      return element;
    })
  }

  const handleSaveData = async () => {
    setDisableButton(true);
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        pgcValue: pgcValue,
        interferenceWithOccupants: JSON.stringify(interferenceWithOccupants),
        temporaryCollectiveProtections,
        vigipirate,
        collectiveProtections: JSON.stringify(collectiveProtections)
      }),
    };
    try {
      const response = await fetch("/api/pgc/updateInterferenceOccupantsData", requestOptions);
      const data = await response.json();
      if (!response.ok) {
        throw new Error(data.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setDisableButton(false);
    }
  }

  const handleGenClick = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let blob = await genPgcPDF(pgcValue, dossierValue, index);
    if (blob) {
      setIsOpen(true);
      setFile(blob);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleShowPdf = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.size > 100) {
      setFile(response.blob);
      setIsOpen(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetPdfFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'pdf');
    if (response.blob.type === 'application/pdf') {
      setFile(response.blob);
      const fileName = response.fileName;
      dispatch(changePdfFileName(fileName))
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleGetWordFile = useCallback(async () => {
    setDisableButton(true);
    setFile(null);
    let response = await getPgcFile(dossierValue, index, 'word');
    if (response.blob.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') {
      setFile(response.blob);
      dispatch(changePdfFileName(response.fileName))
    }
    setDisableButton(false);
  }, [pgcValue]);

  const handleChangeTitleName = useCallback(() => {
    dispatch(changeTitleName(`PGC`));
  }, [pgcValue]);

  const prevPage = () => {
    history.push("/pgc/page4");
  }

  const nextPage = () => {
    history.push("/pgc/page6");
  }

  return (
    <div className="pgc-page5">
      {isLoading && <LoadingOverlay />}
      <Header />
      <DocMenu
        file={file}
        getSourceFile={handleGetWordFile}
        getPDF={handleGetPdfFile}
        handleChangeTitleName={handleChangeTitleName}
      />
      <h1 className="title">Plan Général de Coordination (Interférences avec les occupants)</h1>
      <NumDossPGC />
      <div className="container">
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-title">
              <td className="custom-cell-label"><strong>Description Nature</strong></td>
              <td className="custom-cell-label"><strong>Dimension</strong></td>
              <td className="custom-cell-label"><strong>Localisation</strong></td>
              <td className="custom-cell-label"><strong>Aménagements complémentaires</strong></td>
              <td className="custom-cell-label"><strong>Déplacement nouvelle localisation</strong></td>
              <td className="custom-cell-label"><strong>Lot chargé de la mise en œuvre</strong></td>
              <td className="custom-cell-label"><strong>Lot chargé de l'entretien</strong></td>
              <td className="custom-cell-label"><strong>Répartition des frais</strong></td>
            </tr>
          </thead>
          <tbody>
            <tr className="custom-cell">
              <td>Local bureau Bungalow</td>
              <td>12 m²</td>
              <td>Suivant PIC</td>
              <td>Bureaux, chaises, Téléphones</td>
              <td>Suivant besoin du chantier</td>
              <td>Gros œuvre</td>
              <td>Gros œuvre</td>
              <td><input type="text" name="office" value={interferenceWithOccupants.office} onChange={event => setInterferenceWithOccupants(prevState => ({ ...prevState, office: event.target.value }))} /></td>
            </tr>
            <tr className="custom-cell">
              <td>Local Salle de réunion</td>
              <td>25 à 30 m²</td>
              <td>Suivant PIC</td>
              <td>Tables, chaises, photocopieur</td>
              <td>Suivant besoin du chantier</td>
              <td>Gros œuvre</td>
              <td>Gros œuvre</td>
              <td><input type="text" name="room" value={interferenceWithOccupants.room} onChange={event => setInterferenceWithOccupants(prevState => ({ ...prevState, room: event.target.value }))} /></td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label" colSpan={8}><strong>Protections collectives provisoires </strong></td>
            </tr>
            <tr className="custom-cell">
              <td style={{ textAlign: "center" }} colSpan={4}><span>Ascenseurs</span><input type="checkbox" checked={temporaryCollectiveProtections} onChange={() => setTemporaryCollectiveProtections(!temporaryCollectiveProtections)} style={{ height: "17px" }} /></td>
              <td style={{ textAlign: "center" }} colSpan={4}><span>Plan Vigipirate</span><input type="checkbox" checked={vigipirate} onChange={() => setVigipirate(!vigipirate)} style={{ height: "17px" }} /></td>
            </tr>
          </tbody>
        </table>
        <table className="custom-table">
          <thead>
            <tr className="custom-cell-thead">
              <td className="custom-cell-label" colSpan={6}><strong>Nature des protections collectives préconisée sur ce chantier</strong></td>
            </tr>
            <tr className="custom-cell-title">
              <td className="custom-cell-label"><strong>Nature des travaux</strong></td>
              <td className="custom-cell-label"><strong>Type de protection provisoire ou définitive préconisée et localisation</strong></td>
              <td className="custom-cell-label"><strong>Réalisée et installée par lot (qui ?)</strong></td>
              <td className="custom-cell-label"><strong>Entretenue par lot (Comment ?)</strong></td>
              <td className="custom-cell-label"><strong>Délais<br />(Quand ?)</strong></td>
              <td className="custom-cell-label"></td>
            </tr>
          </thead>
          <tbody>
            {collectiveProtections.map((item, index) => {
              const filteredCollectiveProtectionsConst = collectiveProtectionsConst.filter(el =>
                !collectiveProtections.some(collectiveProtection => collectiveProtection.nature === el.nature && collectiveProtection !== item)
              );
              return (
                <tr className="custom-cell" key={index}>
                  <td style={{ width: "200px" }}>
                    {customInputs[index] ? (
                      <input
                        type="text"
                        value={item.nature}
                        onChange={(e) => setCollectiveProtections(prevState => {
                          const newState = [...prevState];
                          newState[index] = { ...newState[index], nature: e.target.value };
                          return newState;
                        })}
                        placeholder="&quot;Nature des travaux&quot;"
                        style={{ width: "95%", textAlign: "left" }}
                      />
                    ) : (
                      <select value={item.nature} onChange={(e) => handleUpdateCollectiveProtections(e, index)} style={{ width: "200px" }} >
                        <option value={''}></option>
                        {filteredCollectiveProtectionsConst.map((itemDes, indexDes) => (
                          <option value={itemDes.nature} key={indexDes}>{itemDes.nature}</option>
                        ))}
                        <option value="Autre...">Autre...</option>
                      </select>
                    )}
                  </td>
                  <td>
                    {customInputs[index] ? (
                      <textarea
                        name="protection"
                        value={item.protection}
                        onChange={(e) => handleChangeCollectiveProtectionsValues(e, index)}
                        placeholder="&quot;Type de protection&quot;"
                      />
                    ) : (
                      item.protection
                    )}
                  </td>
                  <td style={{ width: "200px" }}>
                    <input
                      type="text"
                      name="completed"
                      value={item.completed}
                      onChange={(e) => handleChangeCollectiveProtectionsValues(e, index)}
                    />
                  </td>
                  <td style={{ width: "200px" }}>
                    <input
                      type="text"
                      name="maintained"
                      value={item.maintained}
                      onChange={(e) => handleChangeCollectiveProtectionsValues(e, index)}
                    />
                  </td>
                  <td style={{ width: "200px" }}>
                    <input
                      type="text"
                      name="deadline"
                      value={item.deadline}
                      onChange={(e) => handleChangeCollectiveProtectionsValues(e, index)}
                    />
                  </td>
                  <td>
                    <IconButton
                      style={{ margin: '0 5px' }}
                      aria-label="delete"
                      size="small"
                      className="delete"
                      disabled={collectiveProtections.length === 1 && collectiveProtections.every((item) => JSON.stringify(item) === JSON.stringify(initialCollectiveProtections))}
                      onClick={() => handleDeleteNatureWork(index)}
                    >
                      <Delete />
                    </IconButton>
                  </td>
                </tr>
              );
            })}
            <tr>
              <td className="add-nature-work">
                <IconButton
                  aria-label="add"
                  size="small"
                  style={{ backgroundColor: "transparent" }}
                  onClick={handleAddNatureWork}
                >
                  <Add />
                </IconButton>
              </td>
            </tr>
          </tbody>
        </table>

      </div>
      <div className="buttons-container">
        <Button
          color="primary"
          variant="contained"
          onClick={handleSaveData}
          disabled={disableButton || !pgcValue}
        >
          Enregistrer
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={handleGenClick}
          disabled={disableButton || !pgcValue}
        >
          Générer le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleShowPdf()}
          disabled={disableButton || !pgcValue}
        >
          Afficher le PDF
        </Button>
        <Button
          color="primary"
          variant="contained"
          startIcon={<ArrowLeft />}
          onClick={prevPage}
          disabled={disableButton || !pgcValue}
        >
          Page précedente
        </Button>
        <Button
          color="primary"
          variant="contained"
          endIcon={<ArrowRight />}
          onClick={nextPage}
          disabled={disableButton || !pgcValue}
        >
          Page suivante
        </Button>
        {disableButton && (
          <CircularProgress className="spinner" color="inherit" />
        )}
      </div>
      <GenPdf
        file={file}
        isOpen={isOpen}
        closeDoc={() => setIsOpen(false)}
        scale={1}
      />
    </div>
  );
};

export default PgcPage5;