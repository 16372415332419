import React, { useState, useEffect, memo } from 'react';
import { format, addMonths } from 'date-fns';
import { normalizeCity, capitalizeFirstLetter } from './Utils';

const ProjectDuration = () => {
  const [data, setData] = useState([]);
  const [hoveredRow, setHoveredRow] = useState(null);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/projectDuration');
        const data = await response.json();
        setData(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleMouseMove = (e) => {
    setTooltipPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div className="scrolling-text-container">
      <table className="scrolling-text-table">
        <thead>
          <tr>
            <th>Dossier</th>
            <th>Durée</th>
            <th>Début</th>
            <th>Fin</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr
              key={index}
              onMouseEnter={() => setHoveredRow(index)}
              onMouseLeave={() => setHoveredRow(null)}
              onMouseMove={handleMouseMove}
              className={hoveredRow === index ? 'hovered' : ''}
            >
              <td>{item.dossier}</td>
              <td>{item.duration ? item.duration_amendment ? item.duration + ' + ' + item.duration_amendment + ' mois' : item.duration + ' mois' : '...'}</td>
              <td>{item.startDate ? format(item.startDate, 'dd/MM/yyyy') : '...'}</td>
              <td>{(item.startDate && item.duration) ? format(addMonths(item.startDate, item.duration_amendment ? item.duration + item.duration_amendment : item.duration), 'dd/MM/yyyy') : '...'}</td>
            </tr>
          ))}
        </tbody>
      </table>
      {hoveredRow !== null && (
        <div
          className="tooltip"
          style={{ left: tooltipPosition.x + 20, top: tooltipPosition.y + 20 }}
        >
          <p><b>Dossier:</b> {data[hoveredRow].dossier}</p>
          {data[hoveredRow].abandoned == 1 && <p><b>Statut:</b> <span style={{ color: 'red' }}>{'Sans suite'}</span></p>}
          <p><b>Opération:</b> {capitalizeFirstLetter(data[hoveredRow].operation)}</p>
          <p><b>Adresse:</b> {(data[hoveredRow].adress && data[hoveredRow].cp && data[hoveredRow].city) ? `${data[hoveredRow].adress}, ${data[hoveredRow].cp} - ${normalizeCity(data[hoveredRow].city)}` : '...'}</p>
          <p><b>Durée{data[hoveredRow].duration_amendment ? ' initiale:' : ':'}</b> {data[hoveredRow].duration ? data[hoveredRow].duration + ' mois' : '...'}</p>
          {data[hoveredRow].duration_amendment && <p><b>Durée avenant:</b> <span style={{ color: 'red' }}>{data[hoveredRow].duration_amendment} mois</span></p>}
          <p><b>Début:</b> {data[hoveredRow].startDate ? format(data[hoveredRow].startDate, 'dd/MM/yyyy') : '...'}</p>
          <p><b>Fin:</b> {data[hoveredRow].startDate && data[hoveredRow].duration ? format(addMonths(data[hoveredRow].startDate, data[hoveredRow].duration_amendment ? data[hoveredRow].duration + data[hoveredRow].duration_amendment : data[hoveredRow].duration), 'dd/MM/yyyy') : '...'}</p>
        </div>
      )}
    </div>
  );
};

export default memo(ProjectDuration);
