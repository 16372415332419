import React, { useState, useEffect, useCallback } from "react";
import '../../Styles/Contact/Contact.scss';
import Header from "../Header/Header";
import LoadingOverlay from "../LoadingOverlay";
import { Button } from "@material-ui/core";
import debounce from 'lodash/debounce';

const Contact = () => {
  const [contactList, setContactList] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [editingContact, setEditingContact] = useState(null);
  const [filteredContacts, setFilteredContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const fetchContactList = async () => {
    try {
      setIsLoading(true);
      const response = await fetch('/api/contact/allContacts');
      const data = await response.json();
      setContactList(data);
      setFilteredContacts(data);
    } catch (error) {
      console.error('Error fetching contact list:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchContactList();
  }, []);

  const debouncedSearch = useCallback(
    debounce((searchValue) => {
      const filtered = contactList.filter(contact => {
        const searchLower = searchValue.toLowerCase();
        return (
          (contact.fonction ?? "").toLowerCase().includes(searchLower) ||
          (contact.nom ?? "").toLowerCase().includes(searchLower) ||
          (contact.prenom ?? "").toLowerCase().includes(searchLower) ||
          (contact.mail ?? "").toLowerCase().includes(searchLower) ||
          (contact.adress ?? "").toLowerCase().includes(searchLower) ||
          (contact.city ?? "").toLowerCase().includes(searchLower) ||
          (contact.cp ?? "").toString().toLowerCase().includes(searchLower) ||
          (contact.tel_port ?? "").toString().toLowerCase().includes(searchLower)
        );
      });
      setFilteredContacts(filtered);
    }, 500), [contactList]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    debouncedSearch(value);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    let updatedValue = value;

    // if (name === 'cp') {
    //   updatedValue = value === '' ? 0 : parseInt(value, 10) || 0;
    // }

    setEditingContact(prev => ({
      ...prev,
      [name]: updatedValue
    }));
  };

  const handleSave = async () => {
    if (editingContact) {
      setIsLoading(true);
      const requestOptions2 = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          idcontact: editingContact.idcontact,
          prenom: editingContact.prenom,
          nom: editingContact.nom,
          sexe: editingContact.sexe,
          tel_port: editingContact.tel_port,
          mail: editingContact.mail,
          adress: editingContact.adress,
          cp: editingContact.cp,
          city: editingContact.city,
        }),
      };
      
      try {
        await fetch("/api/devis/modifContact", requestOptions2);

        const updatedContacts = contactList.map(contact =>
          contact.idcontact === editingContact.idcontact ? editingContact : contact
        );
        setContactList(updatedContacts);
        setFilteredContacts(updatedContacts);
        setEditingContact(null);
      } catch (error) {
        console.error('Error updating contact:', error);
        return;
      } finally {
        setSearchTerm('');
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="contact-list">
      {isLoading && <LoadingOverlay />}
      <Header />
      <h1 className="title">Contacts</h1>

      <div className="search-bar">
        <div className="search-input-wrapper">
          <input
            type="text"
            placeholder="Rechercher..."
            value={searchTerm}
            onChange={handleSearchChange}
          />
          {searchTerm && (
            <button
              className="clear-search"
              onClick={() => {
                setSearchTerm('');
                debouncedSearch('');
              }}
            >
              ✕
            </button>
          )}
        </div>
      </div>

      <div className="contact-container">
        {filteredContacts.map(contact => (
          <div key={contact.idcontact} className="contact-card">
            {editingContact?.idcontact === contact.idcontact ? (
              <div className="edit-form">
                <div className="form-group">
                  <label>Fonction:</label>
                  <b>{editingContact.fonction}</b>
                </div>
                <div className="form-group">
                  <label>Prénom:</label>
                  <input
                    name="prenom"
                    value={editingContact.prenom}
                    onChange={handleEditChange}
                  />
                </div>
                <div className="form-group">
                  <label>Nom:</label>
                  <input
                    name="nom"
                    value={editingContact.nom}
                    onChange={handleEditChange}
                  />
                </div>
                <div className="form-group">
                  <label>Genre:</label>
                  <select
                    name="sexe"
                    value={editingContact.sexe}
                    onChange={handleEditChange}
                  >
                    <option value=""></option>
                    <option value="homme">Homme</option>
                    <option value="femme">Femme</option>
                  </select>
                </div>
                <div className="form-group">
                  <label>Adresse:</label>
                  <input
                    name="adress"
                    value={editingContact.adress}
                    onChange={handleEditChange}
                  />
                </div>
                <div className="form-group">
                  <label>Ville:</label>
                  <input
                    name="city"
                    value={editingContact.city}
                    onChange={handleEditChange}
                  />
                </div>
                <div className="form-group">
                  <label>Code Postal:</label>
                  <input
                    name="cp"
                    value={editingContact.cp}
                    onChange={handleEditChange}
                  />
                </div>
                <div className="form-group">
                  <label>Téléphone:</label>
                  <input
                    name="tel_port"
                    value={editingContact.tel_port}
                    onChange={handleEditChange}
                  />
                </div>
                <div className="form-group">
                  <label>Email:</label>
                  <input
                    name="mail"
                    value={editingContact.mail}
                    onChange={handleEditChange}
                  />
                </div>
                <div className="button-group">
                  <Button
                    variant="contained"
                    size="small"
                    onClick={handleSave}
                  >
                    Enregistrer
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    onClick={() => setEditingContact(null)}
                  >
                    Annuler
                  </Button>
                </div>
              </div>
            ) : (
              <>
                <div className="contact-info">
                  <h3>{contact.prenom} {contact.nom}</h3>
                  <p>Fonction: {contact.fonction}</p>
                  <p>Email: {contact.mail}</p>
                  <p>Téléphone: {contact.tel_port}</p>
                  {contact.adress && <p>
                    Adresse:&nbsp;
                    <>
                      {contact.adress} <br /> {contact.city} - {contact.cp}
                    </>
                  </p>
                  }
                </div>
                <Button
                  className="edit-button"
                  variant="contained"
                  size="small"
                  onClick={() => setEditingContact(contact)}
                >
                  Modifier
                </Button>
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Contact;